import React, { useEffect, useState, useRef } from "react";
import Card from "components/card";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

const Project = () => {
  const [cryptos, setCryptos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const scrollRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchCryptos = async () => {
      try {
        const response = await fetch("/api/socialinfo?beginRange=0&endRange=99");
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }
        const data = await response.json();
        setCryptos(data.cryptos);
      } catch (err) {
        console.error("Error fetching cryptocurrencies:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCryptos();
  }, []);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const scrollInterval = setInterval(() => {
        if (!isHovered) {
          scrollElement.scrollBy(0, 1); // Scroll down 1 pixel
        }
      }, 50); // Adjust scroll speed as needed

      return () => clearInterval(scrollInterval);
    }
  }, [isHovered]);

  // Utility function to format numbers with commas
  const formatNumberWithCommas = (number) => {
    if (number === "N/A" || isNaN(Number(number))) return "N/A";
    return Number(number).toLocaleString();
  };

  return (
    <Card extra="flex flex-col w-full h-[50vh] p-4">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-lg font-bold">Other Hot Coins</h2>
        <span className="text-sm text-gray-400">Real Time Instagram, Reddit, Youtube, X ...</span>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : (
        <div
          ref={scrollRef}
          className="flex flex-col space-y-2 overflow-y-auto scrollbar-hidden"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          // Hide scrollbar for WebKit browsers
          style={{
            scrollbarWidth: 'none', /* Firefox */
            '-ms-overflow-style': 'none', /* IE and Edge */
          }}
        >
          {/* WebKit browsers */}
          <style>{`
            .scrollbar-hidden::-webkit-scrollbar {
              display: none; /* Hides the scrollbar */
            }
          `}</style>
          {cryptos.map((crypto, index) => (
            <div
              key={crypto.id} // Ensure unique key
              className="flex items-center bg-white dark:bg-gray-800 shadow rounded-lg p-2 transform transition duration-200 hover:scale-105 hover:shadow-lg cursor-pointer"
              onClick={() => navigate(`/admin/trending/${crypto.id}`)} // Redirect on click
            >
              <p className="mr-2 text-sm font-semibold text-gray-700 dark:text-gray-300">{index + 1}.</p>
              <img src={crypto.logo_url} alt={`${crypto.name} logo`} className="h-8 w-8" />
              <div className="ml-2">
                <p className="text-sm font-semibold text-gray-700 dark:text-gray-300">{crypto.identity}</p>
                <p className="text-xs text-gray-500">{crypto.symbol}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

export default Project;
