// CheckTable.jsx
import React, { useEffect, useState, useMemo } from "react";
import Card from "components/card";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

function CheckTable() {
  // State variables
  const [cryptos, setCryptos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMarketCap, setShowMarketCap] = useState(true); // Initially show Market Cap
  const [error, setError] = useState(null);
  const [sorting, setSorting] = useState([]); // Sorting state
  const [isMobile, setIsMobile] = useState(false); // Detect mobile devices
  const [currentPage, setCurrentPage] = useState(1); // Pagination

  // Detect screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Column helper from React Table
  const columnHelper = createColumnHelper();

  // Helper function to format prices
  const formatPrice = (price) => {
    const parsedPrice = parseFloat(price);
    if (isNaN(parsedPrice)) return "N/A";
    if (parsedPrice > 0.01) {
      return parsedPrice.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else if (parsedPrice > 0) {
      const fixedSix = parsedPrice.toLocaleString(undefined, {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      });
      if (parseFloat(fixedSix) === 0) {
        return parsedPrice.toExponential(2);
      }
      return fixedSix;
    } else {
      return "0";
    }
  };

  // Helper function to format other numeric values without decimals
  const formatNumber = (number) => {
    const parsedNumber = parseFloat(number);
    if (isNaN(parsedNumber)) return "N/A";
    return parsedNumber.toLocaleString();
  };

  // Define columns for Market Cap view
  const marketCapColumns = useMemo(
    () => [
      columnHelper.accessor("market_cap_rank", {
        id: "rank_name",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white"># Name</p>
        ),
        cell: (info) => {
          const crypto = info.row.original;
          const logoUrl = crypto.logo_url || "default-logo.png";
          return (
            <div className="flex items-center">
              <span className="text-sm font-bold text-navy-700 dark:text-white mr-2">
                {crypto.market_cap_rank}
              </span>
              <img src={logoUrl} alt={`${crypto.identity} logo`} className="h-6 w-6 mr-2" />
              <a
                href={`/dashboard/admin/coins/${encodeURIComponent(crypto.name)}`}
                className="text-sm font-bold text-navy-700 dark:text-white"
              >
                {crypto.identity} ({crypto.symbol.toUpperCase()})
              </a>
            </div>
          );
        },
      }),
      columnHelper.accessor("current_price", {
        id: "price",
        header: () => <p className="text-sm font-bold text-gray-600 dark:text-white">Price</p>,
        cell: (info) => {
          const formattedPrice = formatPrice(info.getValue());
          return (
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              ${formattedPrice}
            </p>
          );
        },
      }),
      columnHelper.accessor("price_change_percentage_24h", {
        id: "24h_change",
        header: () => <p className="text-sm font-bold text-gray-600 dark:text-white">24h %</p>,
        cell: (info) => (
          <p
            className={`text-sm font-bold ${
              parseFloat(info.getValue()) > 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {parseFloat(info.getValue()).toFixed(2)}%
          </p>
        ),
      }),
      columnHelper.accessor("price_change_percentage_1h", {
        id: "1h_change",
        header: () => <p className="text-sm font-bold text-gray-600 dark:text-white">1h %</p>,
        cell: (info) => (
          <p
            className={`text-sm font-bold ${
              parseFloat(info.getValue()) > 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {parseFloat(info.getValue()).toFixed(2)}%
          </p>
        ),
      }),
      columnHelper.accessor("price_change_percentage_7d", {
        id: "7d_change",
        header: () => <p className="text-sm font-bold text-gray-600 dark:text-white">7d %</p>,
        cell: (info) => (
          <p
            className={`text-sm font-bold ${
              parseFloat(info.getValue()) > 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {parseFloat(info.getValue()).toFixed(2)}%
          </p>
        ),
      }),
      columnHelper.accessor("market_cap", {
        id: "marketCap",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Market Cap</p>
        ),
        cell: (info) => {
          const marketCap = info.getValue();
          return (
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              {marketCap === 0 ? "N/A" : `$${formatNumber(marketCap)}`}
            </p>
          );
        },
      }),
      columnHelper.accessor("total_volume", {
        id: "volume",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Volume(24h)</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            ${formatNumber(info.getValue())}
          </p>
        ),
      }),
      columnHelper.accessor("circulating_supply", {
        id: "supply",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Circulating Supply</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {formatNumber(info.getValue())} {info.row.original.symbol.toUpperCase()}
          </p>
        ),
      }),
    ],
    [columnHelper]
  );

  // Define columns for Real Time Trending view
  const realTimeColumns = useMemo(
    () => [
      columnHelper.accessor("alt_rank", {
        id: "rank_name",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white"># Name</p>
        ),
        cell: (info) => {
          const crypto = info.row.original;
          const logoUrl = crypto.logo_url || "default-logo.png";
          return (
            <div className="flex items-center space-x-2">
              <span className="text-sm font-bold text-navy-700 dark:text-white mr-2">
                {crypto.alt_rank}
              </span>
              <img src={logoUrl} alt={`${crypto.identity} logo`} className="h-6 w-6" />
              <a
                href={`/dashboard/admin/trending/${encodeURIComponent(crypto.id)}`}
                className="text-sm font-bold text-navy-700 dark:text-white"
              >
                {crypto.identity} ({crypto.symbol.toUpperCase()})
              </a>
            </div>
          );
        },
      }),
      columnHelper.accessor("current_price", {
        id: "price",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Current Price</p>
        ),
        cell: (info) => {
          const formattedPrice = formatPrice(info.getValue());
          return (
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              ${formattedPrice}
            </p>
          );
        },
      }),
      columnHelper.accessor("price_change_percentage_24h", {
        id: "24h_change",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">24h %</p>
        ),
        cell: (info) => {
          const value = parseFloat(info.getValue());
          if (isNaN(value)) return <p>N/A</p>;
          return (
            <p
              className={`text-sm font-bold ${
                value > 0 ? "text-green-500" : "text-red-500"
              }`}
            >
              {value.toFixed(2)}%
            </p>
          );
        },
      }),
      columnHelper.accessor("market_cap", {
        id: "marketCap",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Market Cap</p>
        ),
        cell: (info) => {
          const marketCap = info.getValue();
          return (
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              {marketCap === '0' ? "N/A" : `$${formatNumber(marketCap)}`}
            </p>
          );
        },
      }),
      columnHelper.accessor("galaxy_score", {
        id: "galaxyScore",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Galaxy Score</p>
        ),
        cell: (info) => {
          const value = parseFloat(info.getValue());
          if (isNaN(value)) return <p>N/A</p>;
          return (
            <p
              className={`text-sm font-bold ${
                value > 50 ? "text-green-500" : "text-red-500"
              }`}
            >
              {value.toFixed(2)}
            </p>
          );
        },
      }),
      columnHelper.accessor("interactions_24h", {
        id: "engagements",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Engagements</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {formatNumber(info.getValue())}
          </p>
        ),
      }),
      columnHelper.accessor("social_volume_24h", {
        id: "mentions",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Mentions</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700 dark:text-white">
            {formatNumber(info.getValue())}
          </p>
        ),
      }),
      columnHelper.accessor("sentiment", {
        id: "sentiment",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Sentiment</p>
        ),
        cell: (info) => {
          const sentimentValue = parseFloat(info.getValue());
          if (isNaN(sentimentValue)) return <p>N/A</p>;
          return (
            <p
              className={`text-sm font-bold ${
                sentimentValue > 0 ? "text-green-500" : "text-red-500"
              }`}
            >
              {sentimentValue.toFixed(2)}
            </p>
          );
        },
      }),
      columnHelper.accessor("social_dominance", {
        id: "socialDominance",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Social Dominance</p>
        ),
        cell: (info) => {
          const dominanceValue = parseFloat(info.getValue());
          if (isNaN(dominanceValue)) return <p>N/A</p>;
          return (
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              {dominanceValue.toFixed(2)}%
            </p>
          );
        },
      }),
      columnHelper.accessor("volatility", {
        id: "volatility",
        header: () => (
          <p className="text-sm font-bold text-gray-600 dark:text-white">Volatility</p>
        ),
        cell: (info) => {
          const value = parseFloat(info.getValue());
          if (isNaN(value)) return <p>N/A</p>;
          return (
            <p className="text-sm font-bold text-navy-700 dark:text-white">
              {value.toFixed(4)}
            </p>
          );
        },
      }),
    ],
    [columnHelper]
  );

  // Combine columns based on view
  const columns = useMemo(
    () => (showMarketCap ? marketCapColumns : realTimeColumns),
    [showMarketCap, marketCapColumns, realTimeColumns]
  );

  // Fetch data based on view and current page
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        let beginRange = (currentPage - 1) * 100;
        let endRange = currentPage * 100 - 1;

        let endpoint = `/api/crypto-data?beginRange=${beginRange}&endRange=${endRange}`;

        if (!showMarketCap) {
          endpoint = `/api/socialinfo?beginRange=${beginRange}&endRange=${endRange}`;
        }

        const response = await fetch(endpoint);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        let fetchedCryptos = [];

        // Depending on the API, adjust the data accordingly
        if (!showMarketCap) {
          if (!Array.isArray(data.cryptos)) {
            throw new Error("Invalid data format for real-time trending cryptos.");
          }
          fetchedCryptos = [...data.cryptos].sort((a, b) => a.alt_rank - b.alt_rank);
        } else {
          if (!Array.isArray(data.cryptos)) {
            throw new Error("Invalid data format for market cap cryptos.");
          }
          fetchedCryptos = [...data.cryptos].sort(
            (a, b) => a.market_cap_rank - b.market_cap_rank
          );
        }

        setCryptos(fetchedCryptos);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, [showMarketCap, currentPage]);

  // Configure React Table
  const table = useReactTable({
    data: cryptos,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Card extra="flex flex-col w-full h-full sm:overflow-auto px-6 pb-6">
      {/* Header with Toggle Switch */}
      <div className="relative flex items-center justify-between pt-4 flex-wrap md:flex-nowrap">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          {showMarketCap
            ? `Top ${currentPage * 100} by Market Cap`
            : `Top ${currentPage * 100} Real Time Trending`}
        </div>
        {/* Toggle Switch */}
        <div className="flex items-center mt-2 md:mt-0">
          <span className="mr-2 text-gray-700 dark:text-gray-300">Real Time Trending</span>
          <label
            htmlFor="toggleMarketCap"
            className="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              id="toggleMarketCap"
              className="sr-only peer"
              checked={showMarketCap}
              onChange={() => {
                setCurrentPage(1); // Reset to page 1 when toggling
                setShowMarketCap(!showMarketCap);
              }}
            />
            {/* Background Switch */}
            <div className="w-11 h-6 bg-gray-200 rounded-full peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:bg-blue-600 transition-colors duration-300 ease-in-out"></div>
            {/* Toggle Circle */}
            <div className="absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </label>
          <span className="ml-2 text-gray-700 dark:text-gray-300">Market Cap</span>
        </div>
      </div>

      {/* Table Section */}
      <div className="mt-8 flex-1 overflow-x-auto">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8H4z"
              ></path>
            </svg>
            <p className="text-center text-gray-500">Loading...</p>
          </div>
        ) : error ? (
          <p className="text-center text-red-500">{error}</p>
        ) : cryptos.length === 0 ? (
          <p className="text-center text-gray-500">No data available for the selected view.</p>
        ) : (
          <table className="w-full min-w-[800px] table-auto">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="!border-px !border-gray-400">
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`sticky top-0 bg-white dark:bg-navy-700 cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start ${
                        header.column.id === "rank_name" ? "sticky left-0 z-30" : ""
                      } ${
                        header.column.id === "rank_name"
                          ? "min-w-[200px]"
                          : "min-w-[150px]"
                      }`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex items-center justify-between text-xs text-gray-600 dark:text-gray-200">
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {/* Add sorting indicator */}
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === "asc" ? (
                            <span>▲</span>
                          ) : (
                            <span>▼</span>
                          )
                        ) : null}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="hover:bg-gray-100 dark:hover:bg-navy-800">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={`border-white/0 py-3 pr-4 ${
                        cell.column.id === "rank_name"
                          ? "sticky left-0 bg-white dark:bg-navy-700 z-10 min-w-[200px]"
                          : "min-w-[150px]"
                      }`}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

     {/* Pagination */}
      <div className="flex justify-end mt-4 space-x-4">
        <span
          onClick={() => currentPage > 1 && setCurrentPage((prev) => prev - 1)}
          className={`cursor-pointer ${
            currentPage === 1 ? "text-gray-400 cursor-not-allowed" : "text-blue-500 hover:underline"
          }`}
        >
          Previous
        </span>
        <span
          onClick={() => currentPage < 40 && setCurrentPage((prev) => prev + 1)}
          className={`cursor-pointer ${
            currentPage === 40 ? "text-gray-400 cursor-not-allowed" : "text-blue-500 hover:underline"
          }`}
        >
          Next
        </span>
      </div>

    </Card>
  );
}

export default CheckTable;
