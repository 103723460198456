import React, { useState, useEffect, useRef } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify, FiSearch } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowBarUp } from "react-icons/bs";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [cryptoStats, setCryptoStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const debounceTimeout = useRef(null);

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fetch search results with debouncing
  useEffect(() => {
    if (query.trim() === "") {
      setResults([]);
      setShowDropdown(false);
      return;
    }

    // Debounce API calls by 300ms
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      fetch(`/geconame/?q=${encodeURIComponent(query)}`)
        .then((response) => response.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setResults(data);
            setShowDropdown(true);
          } else {
            setResults([]);
            setShowDropdown(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching search results:", error);
          setResults([]);
          setShowDropdown(false);
        });
    }, 300);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [query]);

  // Fetch crypto stats on component mount
  useEffect(() => {
    const fetchCryptoStats = async () => {
      try {
        const response = await fetch("/api/stats-and-news/");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCryptoStats(data.crypto_stats); // Adjust based on your API response structure
        setLoading(false);
      } catch (err) {
        console.error("Error fetching crypto stats:", err);
        setError(true);
        setLoading(false);
      }
    };

    fetchCryptoStats();
  }, []);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleResultClick = (identity) => {
    setShowDropdown(false);
    setQuery("");
    navigate(`/admin/coins/${identity}`);
  };

  // Helper functions for formatting
  const formatNumber = (num) => {
    if (num >= 1.0e+12) {
      return (num / 1.0e+12).toFixed(3) + "T";
    } else if (num >= 1.0e+9) {
      return (num / 1.0e+9).toFixed(3) + "B";
    } else if (num >= 1.0e+6) {
      return (num / 1.0e+6).toLocaleString();
    } else if (num >= 1.0e+3) {
      return (num / 1.0e+3).toLocaleString();
    } else {
      return num.toString();
    }
  };

  const formatCurrency = (num) => {
    return "$" + formatNumber(num);
  };

  const formatPercentage = (num) => {
    return num.toFixed(2) + "%";
  };

  // Calculate ALTS
  const calculateAlts = () => {
    if (
      cryptoStats &&
      typeof cryptoStats.market_cap_percentage_btc === "number" &&
      typeof cryptoStats.market_cap_percentage_eth === "number"
    ) {
      const alts = 100 - cryptoStats.market_cap_percentage_btc - cryptoStats.market_cap_percentage_eth;
      return alts.toFixed(2) + "%";
    }
    return "N/A";
  };

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 sm:p-1 backdrop-blur-xl dark:bg-[#0b14374d]">

      <div className="ml-2 sm:ml-1 overflow-x-auto">
        {/* Left Section: Crypto Stats */}
        <div className="flex items-center space-x-6 sm:space-x-4 overflow-x-auto scrollbar-hide">
        {loading ? (
          <p className="text-gray-600 text-sm">Loading crypto stats...</p>
        ) : error ? (
          <p className="text-red-500 text-sm">Error loading crypto stats.</p>
        ) : cryptoStats ? (
          <>
            <div className="text-gray-600 text-sm whitespace-nowrap">
              <span className="font-semibold">Coins:</span>{" "}
              {formatNumber(cryptoStats.active_cryptocurrencies)}
            </div>
            <div className="text-gray-600 text-sm whitespace-nowrap">
              <span className="font-semibold">Exchanges:</span>{" "}
              {formatNumber(cryptoStats.markets)}
            </div>
            <div className="text-gray-600 text-sm whitespace-nowrap">
              <span className="font-semibold">Market Cap:</span>{" "}
              {formatCurrency(cryptoStats.total_market_cap_usd)}{" "}
              <span
                className={`ml-1 ${
                  cryptoStats.market_cap_change_percentage_24h_usd >= 0
                    ? "text-green-400"
                    : "text-red-400"
                }`}
              >
                {formatPercentage(cryptoStats.market_cap_change_percentage_24h_usd)}
              </span>
            </div>
            <div className="text-gray-600 text-sm whitespace-nowrap">
              <span className="font-semibold">24h Vol:</span>{" "}
              {formatCurrency(cryptoStats.total_volume_usd)}
            </div>
            <div className="text-gray-600 text-sm whitespace-nowrap">
              <span className="font-semibold">Dominance:</span>{" "}
              <span className="ml-2">
                BTC {formatPercentage(cryptoStats.market_cap_percentage_btc)}
              </span>
              <span className="ml-2">
                ETH {formatPercentage(cryptoStats.market_cap_percentage_eth)}
              </span>
              <span className="ml-2">
                ALTS {calculateAlts()}
              </span>
            </div>
          </>
        ) : (
          <p className="text-gray-600 text-sm">No crypto stats available.</p>
        )}
        </div>
        <div className="h-6 sm:h-5 w-56 sm:w-44 pt-1">
          <a
            className="text-sm sm:text-xs font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            CoinHeatMap
            <span className="mx-1 text-sm sm:text-xs text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm sm:text-xs font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-3xl sm:text-2xl capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>
  
      <div className="relative mt-1 flex h-[61px] sm:h-[49px] w-[355px] sm:w-[284px] flex-grow items-center justify-around gap-2 sm:gap-1 rounded-full bg-white px-2 sm:px-1 py-2 sm:py-1 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <div
          className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px] sm:w-[180px] relative"
          ref={dropdownRef}
        >
          <p className="pl-3 pr-2 text-xl sm:text-lg">
            <FiSearch className="h-4 w-4 sm:h-3 sm:w-3 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            value={query}
            onChange={handleInputChange}
            onFocus={() => {
              if (results.length > 0) setShowDropdown(true);
            }}
            className="block h-full w-full rounded-full bg-lightPrimary text-sm sm:text-xs font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
          {showDropdown && results.length > 0 && (
            <ul className="absolute top-full left-0 right-0 bg-white dark:bg-navy-900 shadow-lg rounded-b-lg max-h-60 overflow-y-auto z-50">
              {results.map((item) => (
                <li
                  key={item.identity}
                  onClick={() => handleResultClick(item.identity)}
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-navy-700"
                >
                  {item.logo_url && (
                    <img
                      src={item.logo_url}
                      alt={`${item.name} logo`}
                      className="h-6 w-6 rounded-full mr-3"
                    />
                  )}
                  <div>
                    <p className="text-sm font-medium text-navy-700 dark:text-white">
                      {item.name} ({item.symbol.toUpperCase()})
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-600">
                      Rank: {item.market_cap_rank}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <span
          className="flex cursor-pointer text-xl sm:text-lg text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5 sm:h-4 sm:w-4" />
        </span>
        {/* start Notification */}
        <Dropdown
          button={
            <p className="cursor-pointer">
              {/* Uncomment and adjust icon size */}
              {/* <IoMdNotificationsOutline className="h-4 w-4 sm:h-3 sm:w-3 text-gray-600 dark:text-white" /> */}
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] sm:w-[288px] flex-col gap-3 rounded-[20px] bg-white p-4 sm:p-3 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="flex items-center justify-between">
                <p className="text-base sm:text-sm font-bold text-navy-700 dark:text-white">
                Whether you're a seasoned trader or new to the crypto space, CoinHeatMap provides the tools and information you need to stay ahead. Explore our social volume heatmap, track trending coins, and engage with our community.
                </p>
                <p className="text-sm sm:text-xs font-bold text-navy-700 dark:text-white">
                CoinHeatMap is your ultimate destination for real-time crypto analytics. We specialize in social volume analysis, helping you identify trending coins before they make headlines. Our platform features a Multichain EVM swap powered by 1inch, allowing you to swap tokens seamlessly across multiple EVM chains.
                  
                </p>
              </div>

              <button className="flex w-full items-center">
                <div className="flex h-full w-[85px] sm:w-[68px] items-center justify-center rounded-xl bg-gradient-to-b from-brandLinear to-brand-500 py-4 sm:py-3 text-2xl sm:text-xl text-white">
                  <BsArrowBarUp />
                </div>
                <div className="ml-2 sm:ml-1 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm sm:text-xs">
                  <p className="mb-1 text-left text-base sm:text-sm font-bold text-gray-900 dark:text-white">
                    Use to earn crypto pricing site.
                  </p>
                  <p className="font-base text-left text-xs sm:text-[10px] text-gray-900 dark:text-white">
                    {/* Add notification details here */}
                  </p>
                </div>
              </button>
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        />
        {/* start Horizon PRO */}
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdInformationCircleOutline className="h-4 w-4 sm:h-3 sm:w-3 text-gray-600 dark:text-white" />
            </p>
          }
          children={
            <div className="flex w-[350px] sm:w-[280px] flex-col gap-2 rounded-[20px] bg-white p-4 sm:p-3 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
                className="mb-2 aspect-video w-full rounded-lg"
              />
            </div>
          }
          classNames={"py-2 top-6 -left-[250px] md:-left-[330px] w-max"}
          animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
        />
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 sm:h-3 sm:w-3 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 sm:h-3 sm:w-3 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              // Uncomment and adjust image size
              // className="h-10 w-10 sm:h-8 sm:w-8 rounded-full"
              // src={avatar}
              // alt="User Avatar"
            />
          }
          children={
            <div className="flex w-56 sm:w-44 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="p-4 sm:p-3">
                <div className="flex items-center gap-2 sm:gap-1">
                  <p className="text-sm sm:text-xs font-bold text-navy-700 dark:text-white">
                    Crypto Heatmaps
                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />
              <div className="flex flex-col p-4 sm:p-3">
                <a
                  href=" "
                  className="text-sm sm:text-xs text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Real Time Social Volume
                </a>
                <a
                  href=" "
                  className="mt-3 sm:mt-2 text-sm sm:text-xs text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Next 100x
                </a>
                <a
                  href=" "
                  className="mt-3 sm:mt-2 text-sm sm:text-xs font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in"
                >
                
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;


 