import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const TooltipPortal = ({ tooltipRef }) => {
  useEffect(() => {
    const handleMouseMove = (event) => {
      const tooltipElement = tooltipRef.current;
      const { pageX: mouseX, pageY: mouseY } = event; // Changed from clientX/Y to pageX/Y

      if (tooltipElement) {
        const tooltipRect = tooltipElement.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        // Default positions near the mouse
        let left = mouseX + 15;
        let top = mouseY + 15;

        // Adjust if it goes beyond the right edge
        if (left + tooltipRect.width > window.pageXOffset + windowWidth) {
          left = mouseX - tooltipRect.width - 15;
        }

        // Adjust if it goes beyond the bottom edge
        if (top + tooltipRect.height > window.pageYOffset + windowHeight) {
          top = mouseY - tooltipRect.height - 15;
        }

        // Apply the calculated positions
        tooltipElement.style.left = `${left}px`;
        tooltipElement.style.top = `${top}px`;
      }
    };

    // Add mousemove event listener
    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [tooltipRef]);

  return ReactDOM.createPortal(
    <div
      id="tooltip"
      ref={tooltipRef}
      style={{
        position: "absolute",
        visibility: "hidden",
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        padding: "10px",
        borderRadius: "6px",
        pointerEvents: "none",
        fontFamily: "Avenir",
        zIndex: 1000,
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.3)",
        maxWidth: "300px", // Set a maximum width to prevent it from becoming too wide
        wordWrap: "break-word", // Ensure long text wraps to the next line
      }}
    ></div>,
    document.body // Renders the tooltip at the end of the body
  );
};

export default TooltipPortal;
