import React from "react";
import Card from "components/card";
import PropTypes from "prop-types"; // Optional: For prop type checking
import { useExtractColors } from "react-extract-colors";

const Banner = ({ coinData }) => {
  // Extract the image URL safely
  const url = coinData.logo_url;

  const image = coinData.logo_url
  ? `https://cors-anywhere.herokuapp.com/${coinData.logo_url}`
  : coinData.logo_url;

  console.log("Logo URL", image);

  // Use the hook to extract colors from the image
  const { colors, error, isLoading } = useExtractColors(image, {
    maxColors: 5,
    format: "hex",
    orderBy: "dominance",
  });

  // Log extracted colors for debugging
  console.log("Extracted Colors:", colors);

  // Construct gradient from extracted colors
  const gradientColors =
    colors && colors.length > 0
      ? colors.slice(0, 3).join(", ")
      : "#000000, #333333, #666666"; // Fallback colors if extraction fails

  // Define the background style with the dynamic gradient
  const backgroundStyle = {
    backgroundImage: `linear-gradient(to right, ${gradientColors})`,
    transition: "background-image 0.5s ease-in-out", // Optional: Smooth transition
  };

  // Prepare the price change percentage
  const percentageChangeRaw = coinData.price_change_percentage_24h;
  const percentageChange = parseFloat(percentageChangeRaw);

  let percentageChangeFormatted = "N/A";
  let percentageChangeClass = "text-gray-500";
  let arrow = null;

  if (!isNaN(percentageChange)) {
    percentageChangeFormatted = `${percentageChange.toFixed(2)}%`;

    if (percentageChange > 0) {
      percentageChangeClass = "text-green-500";
      arrow = "↑";
    } else if (percentageChange < 0) {
      percentageChangeClass = "text-red-500";
      arrow = "↓";
    } else {
      percentageChangeClass = "text-gray-500"; // For zero change
    }
  }

  /**
   * Formats the price based on its value.
   * - For prices >= 0.01: Adds commas and two decimal places.
   * - For prices < 0.01: Adds commas and higher precision using toPrecision.
   *
   * @param {string | number} price - The price to format.
   * @returns {string} - The formatted price string.
   */
  const formatPrice = (price) => {
    if (price === "N/A") return "N/A";

    const numberPrice = Number(price);
    if (isNaN(numberPrice)) return "N/A";

    if (numberPrice >= 0.01) {
      // Format with commas and two decimal places
      return `$${numberPrice.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    } else {
      // Format with commas and higher precision using toPrecision
      // Here, we use 6 significant digits for better precision
      const preciseNumber = numberPrice.toPrecision(6);
      // Convert back to number to apply toLocaleString
      return `$${Number(preciseNumber).toLocaleString("en-US", {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
      })}`;
    }
  };

  return (
    <Card extra="items-center w-full h-full p-4 bg-cover">
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl"
        style={backgroundStyle}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-white dark:!border-navy-700">
          {/* Crypto Logo */}
          <img
            className="h-full w-full rounded-full object-cover"
            src={coinData.logo_url} // Correct property name
            alt={`${coinData.identity} logo`} // Improved alt text for accessibility
          />
        </div>
      </div>

      {/* Name and Symbol */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {coinData.identity.charAt(0).toUpperCase() +
            coinData.identity.slice(1)}
        </h4>
        <p className="text-base font-normal text-gray-600">
          {coinData.symbol.toUpperCase()}
        </p>
      </div>

      {/* Additional Information */}
      <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        {/* Market Cap Rank */}
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            {coinData.market_cap_rank || "N/A"}
          </p>
          <p className="text-sm font-normal text-gray-600">Market Cap Rank</p>
        </div>
        {/* Current Price */}
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            {coinData.current_price !== "N/A"
              ? formatPrice(coinData.current_price)
              : "N/A"}
          </p>
          <p className="text-sm font-normal text-gray-600">Current Price</p>
        </div>
        {/* Price Change Percentage with Arrow */}
        <div className="flex flex-col items-center justify-center">
          <p
            className={`text-2xl font-bold ${percentageChangeClass} flex items-center`}
          >
            {arrow && <span className="mr-1">{arrow}</span>}
            {percentageChangeFormatted}
          </p>
          <p className="text-sm font-normal text-gray-600">24h Change</p>
        </div>
      </div>
    </Card>
  );
};

// Optional: Define PropTypes for better type-checking
Banner.propTypes = {
  coinData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    identity: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo_url: PropTypes.string.isRequired,
    price_change_percentage_24h: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    market_cap_rank: PropTypes.number,
    current_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // Add other fields as necessary
  }).isRequired,
};

export default Banner;
