import React, { useEffect, useState, useCallback } from "react";
import Card from "components/card";
import {
  MdCategory,
  MdLink,
  MdImage,
  MdSentimentSatisfied,
  MdPerson,
  MdThumbUp,
  MdThumbDown,
  MdRefresh,
} from "react-icons/md";

const MiniCalendar = () => {
  const [data, setData] = useState([]); // Holds either news or blogs
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewType, setViewType] = useState("news"); // 'news' or 'blogs'

  // Fetch Latest News
  const fetchNews = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/stats-and-news");
      if (!response.ok) {
        throw new Error(`Failed to fetch news: ${response.status}`);
      }
      const data = await response.json();
      console.log("Fetched news data:", data);

      if (data && Array.isArray(data.latest_lunar_news)) {
        setData(data.latest_lunar_news);
      } else {
        throw new Error("Unexpected data structure for news");
      }
    } catch (err) {
      console.error("Error fetching news:", err);
      setError(err.message);
      setData([]);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch Latest Blogs
  const fetchBlogs = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/latest-blogs/");
      if (!response.ok) {
        throw new Error(`Failed to fetch blogs: ${response.status}`);
      }
      const data = await response.json();
      console.log("Fetched blogs data:", data);

      if (Array.isArray(data)) {
        setData(data);
      } else {
        throw new Error("Unexpected data structure for blogs");
      }
    } catch (err) {
      console.error("Error fetching blogs:", err);
      setError(err.message);
      setData([]);
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch data based on viewType
  const fetchData = useCallback(() => {
    if (viewType === "news") {
      fetchNews();
    } else if (viewType === "blogs") {
      fetchBlogs();
    }
  }, [viewType, fetchNews, fetchBlogs]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle Toggle Between News and Blogs
  const handleToggle = () => {
    setViewType((prev) => (prev === "news" ? "blogs" : "news"));
  };

  // Helper Function to Format Numbers
  const formatNumber = (number) => {
    return Number(number).toLocaleString(undefined, { maximumFractionDigits: 0 });
  };

  return (
    <div>
      <Card extra="flex flex-col w-full h-[400px] p-4">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-bold">
            {viewType === "news" ? "Latest News" : "Latest Blogs"}
          </h2>
          <div className="flex space-x-2">
            <button
              onClick={handleToggle}
              className="flex items-center text-blue-500 hover:underline"
              disabled={loading}
            >
              {viewType === "news" ? "Show Blogs" : "Show News"}
            </button>
            <button
              onClick={fetchData}
              className="flex items-center text-blue-500 hover:underline"
              disabled={loading}
            >
              <MdRefresh className="mr-1" /> Refresh
            </button>
          </div>
        </div>

        {loading ? (
          <p>Loading {viewType}...</p>
        ) : error ? (
          <p className="text-red-500">Error: {error}</p>
        ) : data.length === 0 ? (
          <p>No {viewType} available.</p>
        ) : (
          <div className="space-y-4 overflow-y-auto max-h-[500px]">
            {viewType === "news" ? (
              data.map((item) => (
                <div
                  key={item.id}
                  className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 flex flex-col md:flex-row"
                >
                  {/* Post Image */}
                  {item.post_image && item.post_image !== "N/A" && (
                    <img
                      src={item.post_image}
                      alt={item.post_title}
                      className="w-full md:w-1/4 h-32 object-cover rounded-md"
                    />
                  )}

                  <div className="md:ml-4 flex-1">
                    {/* Post Title */}
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                      <a
                        href={item.post_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        {item.post_title}
                      </a>
                    </h3>

                    {/* Sentiment */}
                    <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mt-2">
                      <MdSentimentSatisfied className="mr-1" />
                      <span>Sentiment: {item.post_sentiment}</span>
                    </div>

                    {/* Creator Information */}
                    <div className="flex items-center mt-2">
                      {item.creator_avatar && item.creator_avatar !== "N/A" && (
                        <img
                          src={item.creator_avatar}
                          alt={item.creator_name}
                          className="w-8 h-8 rounded-full mr-2"
                        />
                      )}
                      <div>
                        <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
                          {item.creator_display_name !== "N/A"
                            ? item.creator_display_name
                            : item.creator_name}
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          Followers: {formatNumber(item.creator_followers)}
                        </p>
                      </div>
                    </div>

                    {/* Interactions */}
                    <div className="flex items-center mt-2 text-sm text-gray-500 dark:text-gray-400">
                      <MdThumbUp className="mr-1" />
                      <span>{formatNumber(item.interactions_24h)} Interactions (24h)</span>
                      <span className="mx-2">|</span>
                      <span>{formatNumber(item.interactions_total)} Total Interactions</span>
                    </div>

                    {/* Post Metadata */}
                    <div className="flex items-center text-xs text-gray-400 dark:text-gray-500 mt-2">
                      <span>
                        Created At:{" "}
                        {item.post_created !== "N/A"
                          ? new Date(item.post_created).toLocaleString()
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              // Render Blogs
              data.map((blog) => (
                <div
                  key={blog.slug}
                  className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 flex flex-col"
                >
                  {/* Blog Image */}
                  {blog.post_image && blog.post_image !== "N/A" && (
                    <img
                      src={blog.post_image}
                      alt={blog.title}
                      className="w-full h-32 object-cover rounded-md"
                    />
                  )}

                  <div className="mt-4">
                    {/* Blog Title */}
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
                      <a
                        href={`https://coinheatmap.com/blog/${blog.slug}/`}
                        className="hover:underline"
                      >
                        {blog.title}
                      </a>
                    </h3>

                    {/* Author and Date */}
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                      By {blog.author_name} on {new Date(blog.created_date).toLocaleDateString()}
                    </p>

                    {/* Main Topic */}
                    <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mt-2">
                      <MdCategory className="mr-1" />
                      <span>{blog.main_topic}</span>
                    </div>

                    {/* Add more blog-specific details here if needed */}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export default MiniCalendar;
