import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import SocialProfile from "views/admin/socialprofile";
import DataTables from "views/admin/tables/index.jsx";
import Bubbles from "views/admin/bubbles/Bubbles.jsx";
import RTLDefault from "views/rtl/default";
import Heatmaps from "views/admin/heatmaps/Heatmaps.jsx";
import Top100 from "views/admin/tables/Top100.jsx";
import SwapIframeComponent from "views/admin/swap/Swap.jsx";
import BlogIframeComponent from "views/admin/blog/Blog.jsx";

// Auth Imports
import SignIn from "views/auth/SignIn";
import { Navigate } from "react-router-dom";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdOutlineArtTrack,
  MdBarChart,
  MdPerson,
  MdLock,
  MdOutlineWhatshot,
  MdBubbleChart,
  MdSwapHoriz,
  MdOutlineCurrencyBitcoin,
  MdOutlineTrendingUp,
  MdOutlineNewspaper,
  MdToken,
} from "react-icons/md";

const routes = [
  {
    name: "Coin Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: MainDashboard, // Pass the component itself
  },
  {
     name: "$HMAP",
     layout: "/admin",
     path: "hmap-token",
     icon: <MdToken className="h-6 w-6" />,
     component: NFTMarketplace, // Pass the component itself
     secondary: true,
  },
  {
    name: "Coin Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "coin-tables",
    component: Top100, // Pass the component itself
  },
  {
    name: "Heatmaps",
    layout: "/admin",
    icon: <MdOutlineWhatshot className="h-6 w-6" />,
    path: "heatmaps",
    component: Heatmaps, // Pass the component itself
  },
  {
    name: "Bubbles",
    layout: "/admin",
    icon: <MdBubbleChart className="h-6 w-6" />,
    path: "bubbles",
    component: Bubbles, // Pass the component itself
  },
  {
    name: "Coins", // Optional: Update the name to reflect the new route
    layout: "/admin",
    icon: <MdOutlineCurrencyBitcoin className="h-6 w-6" />,
    path: "coins/:coinID", // Dynamic route
    component: Profile, // Pass the component itself
  },
  {
    name: "Trending", // Optional: Update the name to reflect the new route
    layout: "/admin",
    icon: <MdOutlineTrendingUp className="h-6 w-6" />,
    path: "trending/:socialID", // Dynamic route
    component: SocialProfile, // Pass the component itself
  },
  {
    name: "Blog", // Optional: Update the name to reflect the new route
    layout: "/admin",
    icon: <MdOutlineNewspaper className="h-6 w-6" />,
    path: "blog", // Dynamic route
    component: BlogIframeComponent, // Pass the component itself
  },
  {
    name: "Swap",
    layout: "/admin",
    icon: <MdSwapHoriz className="h-6 w-6" />,
    path: "swap",
    component: SwapIframeComponent, // Pass the component itself
  },
  
  // Uncomment and correct as needed
  /*
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: SignIn,
  },
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "rtl",
    icon: <MdHome className="h-6 w-6" />,
    component: RTLDefault,
  },
  */
];

export default routes;
