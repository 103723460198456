const FreeCard = () => {
  return (
    <div >
      <div >
        <svg
          width="41"
          height="41"
          viewBox="0 0 41 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="white"
          />
          <path
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            fill="white"
          />
        </svg>
      </div>

    </div>
  );
};

export default FreeCard;
