// src/views/admin/swap/Swap.jsx

import React, { useEffect } from 'react';

const BlogRedirectComponent = () => {
  useEffect(() => {
    // Redirect to the Swap interface
    window.location.replace('https://www.coinheatmap.com/blog');
    // Alternatively, to open in a new tab, use:
    // window.open('https://www.coinheatmap.com/swap', '_blank');
  }, []);

  return (
    <div style={styles.container}>
      <p>Redirecting to the Blog ...</p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '50px',
    fontFamily: 'Arial, sans-serif',
  },
};

export default BlogRedirectComponent;
