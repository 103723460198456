import Card from "components/card";
import CardMenu from "components/card/CardMenu";
import React from "react";

const formatNumber = (number) => {
  return parseFloat(number).toLocaleString();
};

const formatPercentage = (percentage) => {
  return percentage ? `${parseFloat(percentage).toFixed(2)}%` : "N/A";
};

const Storage = ({ coinData }) => {
  const {
    market_cap,
    market_cap_change_percentage_24h,
    fully_diluted_market_cap,
    total_volume,
    total_supply,
    max_supply,
    circulating_supply,
    symbol,
  } = coinData;

  // Calculate Volume/Market Cap ratio
  const volumeMarketCapRatio =
    total_volume && market_cap
      ? ((parseFloat(total_volume) / parseFloat(market_cap)) * 100).toFixed(2)
      : "N/A";

  // Calculate Circulating Supply fill percentage
  const circulatingSupplyFill =
    circulating_supply && max_supply
      ? (parseFloat(circulating_supply) / parseFloat(max_supply)) * 100
      : 0;

  return (
    <Card extra={"w-full h-full p-3"}>
      <div className="mb-auto flex flex-col items-center justify-center">
        <h4 className="mb-1 mt-2 text-lg font-bold text-navy-700 dark:text-white">
          Market Overview
        </h4>
        <p className="px-4 text-center text-sm font-normal text-gray-600 md:!px-0 xl:!px-6"></p>
      </div>

      {/* Data display */}
      <div className="grid grid-cols-2 gap-3 mt-3 text-center">
        {/* Market Cap */}
        <div className="flex flex-col p-2 rounded-lg shadow bg-white dark:bg-navy-700 overflow-hidden">
          <span className="text-xs font-normal text-gray-600 break-words">
            Market cap
          </span>
          <span className="text-base font-bold text-navy-700 dark:text-white">
            {market_cap ? `$${formatNumber(market_cap)}` : "N/A"}
          </span>
          <span
              className={`text-xs font-normal ${
                market_cap_change_percentage_24h < 0
                  ? 'text-red-500'
                  : market_cap_change_percentage_24h > 0
                  ? 'text-green-500'
                  : 'text-gray-500'
              }`}
            >
            {formatPercentage(market_cap_change_percentage_24h)}
          </span>
        </div>

        {/* Volume */}
        <div className="flex flex-col p-2 rounded-lg shadow bg-white dark:bg-navy-700 overflow-hidden">
          <span className="text-xs font-normal text-gray-600 break-words">
            Volume (24h)
          </span>
          <span className="text-base font-bold text-navy-700 dark:text-white">
            {total_volume ? `$${formatNumber(total_volume)}` : "N/A"}
          </span>
        </div>

        {/* FDV */}
        <div className="flex flex-col p-2 rounded-lg shadow bg-white dark:bg-navy-700 overflow-hidden">
          <span className="text-xs font-normal text-gray-600 break-words">
            FDV
          </span>
          <span className="text-base font-bold text-navy-700 dark:text-white">
            {fully_diluted_market_cap
              ? `$${formatNumber(fully_diluted_market_cap)}`
              : "N/A"}
          </span>
        </div>

        {/* Volume/Market Cap Ratio */}
        <div className="flex flex-col p-2 rounded-lg shadow bg-white dark:bg-navy-700 overflow-hidden">
          <span className="text-xs font-normal text-gray-600 break-words">
            Vol/Mkt Cap (24h)
          </span>
          <span className="text-base font-bold text-navy-700 dark:text-white">
            {volumeMarketCapRatio}%
          </span>
        </div>

        {/* Total Supply */}
        <div className="flex flex-col p-2 rounded-lg shadow bg-white dark:bg-navy-700 overflow-hidden">
          <span className="text-xs font-normal text-gray-600 break-words">
            Total supply
          </span>
          <span className="text-base font-bold text-navy-700 dark:text-white">
            {total_supply ? `${formatNumber(total_supply)}` : "N/A"}
          </span>
        </div>

        {/* Max Supply */}
        <div className="flex flex-col p-2 rounded-lg shadow bg-white dark:bg-navy-700 overflow-hidden">
          <span className="text-xs font-normal text-gray-600 break-words">
            Max supply
          </span>
          <span className="text-base font-bold text-navy-700 dark:text-white">
            {max_supply ? `${formatNumber(max_supply)}` : "N/A"}
          </span>
        </div>

        {/* Circulating Supply with Loader */}
        <div className="flex flex-col col-span-2 p-2 rounded-lg shadow bg-white dark:bg-navy-700 overflow-hidden">
          <span className="text-xs font-normal text-gray-600 break-words">
            Circulating supply
          </span>
          <div className="text-base font-bold text-navy-700 dark:text-white flex items-center justify-center gap-2">
            {circulating_supply ? `${formatNumber(circulating_supply)} ${symbol}` : "N/A"}
          </div>
          <div className="relative w-full h-2 mt-1 bg-lightPrimary dark:bg-navy-800 rounded-full overflow-hidden">
            <div
              className="h-full bg-brand-500 dark:bg-blue-500 rounded-full"
              style={{ width: `${circulatingSupplyFill}%` }}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Storage;
