/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import CHMlogo from './CHMLogo.png';
import CHMlogowhite from './CHMLogoWhite.png';

import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes.js";

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-left `}>
         {/* Light Mode Logo */}
        <img 
          src={CHMlogo} 
          alt="Coin HeatMap Logo" 
          className="mr-2 dark:hidden" 
          style={{ width: '175px', height: 'auto', marginLeft: '0' }} // Adjust the width as needed
        />
        
        {/* Dark Mode Logo */}
        <img 
          src={CHMlogowhite} 
          alt="Coin HeatMap Logo White" 
          className="mr-2 hidden dark:block" 
          style={{ width: '175px', height: 'auto', marginLeft: '0' }} // Adjust the width as needed
        />
      </div>
      <div class="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center">
        <SidebarCard />
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
