import React from 'react';
import CheckTable from "../default/components/CheckTable.jsx";

const Top100 = () => {
  return (
    <div className="flex flex-col w-full h-full p-4">
      <div className="flex-1 w-full overflow-auto">
        <CheckTable />
      </div>
    </div>
  );
};

export default Top100;
