import React, { useEffect, useState, useRef, useMemo } from "react";
import Card from "components/card";

const TaskCard = () => {
  const [cryptos, setCryptos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const scrollRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [sortOption, setSortOption] = useState("volume"); // Default sort by volume

  // Fetch the top 100 cryptocurrencies
  const fetchCryptos = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/crypto-data?beginRange=0&endRange=99");
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status}`);
      }
      const data = await response.json();
      setCryptos(data.cryptos);
    } catch (err) {
      console.error("Error fetching cryptocurrencies:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCryptos();
  }, []);

  // Auto-scroll functionality
  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const scrollInterval = setInterval(() => {
        if (!isHovered) {
          scrollElement.scrollBy(0, 1); // Scroll down 1 pixel
        }
      }, 50); // Adjust scroll speed as needed

      return () => clearInterval(scrollInterval);
    }
  }, [isHovered]);

  // Handle sorting option change
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  // Function to format price with commas and conditional precision
  const formatPrice = (price) => {
    const num = parseFloat(price);
    if (isNaN(num)) return "N/A";

    if (num < 0.01 && num !== 0) {
      // For very small numbers, use exponential notation or more decimal places
      return num.toLocaleString(undefined, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 6,
      });
    }
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  // Sort the cryptos based on the selected sort option
  const sortedCryptos = useMemo(() => {
    if (!cryptos) return [];

    let sorted = [...cryptos]; // Create a shallow copy to avoid mutating state

    switch (sortOption) {
      case "volume":
        sorted.sort((a, b) => {
          const volA = parseFloat(a.total_volume) || 0;
          const volB = parseFloat(b.total_volume) || 0;
          return volB - volA; // Descending order
        });
        break;
      case "gainers":
        // Filter out cryptos with negative or zero price changes
        sorted = sorted.filter(
          (crypto) => parseFloat(crypto.price_change_percentage_24h) > 0
        );
        sorted.sort((a, b) => {
          const gainA = parseFloat(a.price_change_percentage_24h) || 0;
          const gainB = parseFloat(b.price_change_percentage_24h) || 0;
          return gainB - gainA; // Descending order
        });
        break;
      case "losers":
        // Filter out cryptos with positive or zero price changes
        sorted = sorted.filter(
          (crypto) => parseFloat(crypto.price_change_percentage_24h) < 0
        );
        sorted.sort((a, b) => {
          const lossA = parseFloat(a.price_change_percentage_24h) || 0;
          const lossB = parseFloat(b.price_change_percentage_24h) || 0;
          return lossA - lossB; // Ascending order (most negative first)
        });
        break;
      case "ath":
        sorted.sort((a, b) => {
          const athA = parseFloat(a.ath) || 0;
          const athB = parseFloat(b.ath) || 0;
          return athB - athA; // Descending order
        });
        break;
      case "atl":
        sorted.sort((a, b) => {
          const atlA = parseFloat(a.atl) || 0;
          const atlB = parseFloat(b.atl) || 0;
          return atlA - atlB; // Ascending order
        });
        break;
      default:
        break;
    }

    return sorted;
  }, [cryptos, sortOption]);

  return (
    <Card extra="flex flex-col w-full h-[400px] p-4 relative"> {/* Added 'relative' for positioning */}
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-bold">Top 100 Rankings</h2>
        <div className="text-lg font-sm right-1 bottom-1">Real Time</div>
      </div>

      {/* Sorting Controls positioned at the top right */}
      <div className="absolute top-4 right-4 flex items-center z-10">
        <label htmlFor="sort" className="mr-2 text-sm font-medium text-gray-700">
          Sort By:
        </label>
        <select
          id="sort"
          value={sortOption}
          onChange={handleSortChange}
          className="px-2 py-1 border border-gray-400 rounded bg-gray-500 text-white"
        >
          <option value="volume">Top Volume</option>
          <option value="gainers">Top Gainers</option>
          <option value="losers">Top Losers</option>
          {/* Removed ROI option */}
          <option value="ath">All Time High</option>
          <option value="atl">All Time Low</option>
        </select>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : sortedCryptos.length === 0 ? (
        <p className="text-gray-500">No data available for the selected sort option.</p>
      ) : (
        <div
          ref={scrollRef}
          className="flex flex-col space-y-2 overflow-y-auto scrollbar-hidden"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            scrollbarWidth: "none", // Firefox
            msOverflowStyle: "none", // IE and Edge
          }}
        >
          {/* WebKit browsers */}
          <style>{`
            .scrollbar-hidden::-webkit-scrollbar {
              display: none; /* Hides the scrollbar */
            }
          `}</style>
          {sortedCryptos.map((crypto, index) => (
            <div
              key={crypto.identity}
              className="flex items-center bg-white dark:bg-gray-800 shadow rounded-lg p-2 transition-transform transform hover:scale-105"
            >
              <p className="mr-2 text-sm font-semibold text-gray-700 dark:text-gray-300">
                {index + 1}.
              </p>
              {crypto.logo_url && (
                <img
                  src={crypto.logo_url}
                  alt={`${crypto.name} logo`}
                  className="h-8 w-8 rounded-full"
                />
              )}
              <div className="ml-2 flex-1">
                <p className="text-sm font-semibold text-gray-700 dark:text-gray-300">
                  {crypto.identity} ({crypto.symbol})
                </p>
                <p className="text-xs text-gray-500">
                  Price: ${formatPrice(crypto.current_price)}
                </p>
              </div>
              <div className="ml-auto text-right">
                {sortOption === "volume" && (
                  <p className="text-xs text-gray-500">
                    Volume: ${Number(crypto.total_volume).toLocaleString()}
                  </p>
                )}
                {(sortOption === "gainers" || sortOption === "losers") && (
                  <p
                    className={`text-xs ${
                      parseFloat(crypto.price_change_percentage_24h) >= 0
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >
                    24h: {parseFloat(crypto.price_change_percentage_24h).toFixed(2)}%
                  </p>
                )}
                {sortOption === "ath" && (
                  <p className="text-xs text-gray-500">
                    ATH: ${formatPrice(crypto.ath)}
                  </p>
                )}
                {sortOption === "atl" && (
                  <p className="text-xs text-gray-500">
                    ATL: ${formatPrice(crypto.atl)}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

export default TaskCard;
