import React, { useState } from "react";
import Card from "components/card";
import PropTypes from "prop-types";

// Function to format crypto prices with commas and appropriate precision
const formatPrice = (price) => {
  if (price === null || price === undefined) return "N/A";
  if (price === 0) return "0.00";

  const absPrice = Math.abs(price);
  let decimalPlaces;

  if (absPrice < 0.0001) {
    decimalPlaces = 12;
  } else if (absPrice < 0.01) {
    decimalPlaces = 10;
  } else if (absPrice < 1) {
    decimalPlaces = 5;
  } else {
    decimalPlaces = 2;
  }

  // Format the number with fixed decimal places
  let formattedPrice = price.toFixed(decimalPlaces);

  // Remove trailing zeros and unnecessary decimal points
  formattedPrice = formattedPrice.replace(/\.?0+$/, '');

  // Split into integer and fractional parts
  const [integerPart, fractionalPart] = formattedPrice.split('.');

  // Use Intl.NumberFormat to add commas to the integer part
  const formatter = new Intl.NumberFormat('en-US');
  const formattedInteger = formatter.format(parseInt(integerPart, 10));

  // Reassemble the number with commas and fractional part
  return fractionalPart !== undefined ? `${formattedInteger}.${fractionalPart}` : formattedInteger;
};

// Utility functions for formatting percentages and ROI
const formatPercentage = (percentage) => {
  if (percentage === "N/A" || isNaN(percentage)) return "N/A";
  return `${parseFloat(percentage).toFixed(2)}%`;
};

// Function to format ROI data
const formatRoi = (roi) => {
  if (roi && typeof roi === "object" && roi.times) {
    return `x${roi.times.toFixed(2)}`;
  }
  return "N/A";
};

const Upload = ({ coinData }) => {
  // State to hold the USD input value
  const [usdAmount, setUsdAmount] = useState("");

  // Function to handle changes in the USD input field
  const handleUsdChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setUsdAmount(value);
    }
  };

  // Function to calculate the Crypto amount based on USD input
  const calculateCrypto = () => {
    const currentPrice = parseFloat(coinData.current_price);
    if (
      usdAmount === "" ||
      isNaN(currentPrice) ||
      currentPrice === 0
    ) {
      return "0";
    }
    const cryptoAmount = parseFloat(usdAmount) / currentPrice;
    return formatPrice(cryptoAmount);
  };

  // Function to safely parse and format prices
  const getFormattedPrice = (price) => {
    const parsedPrice = parseFloat(price);
    return isNaN(parsedPrice) ? "N/A" : formatPrice(parsedPrice);
  };

  return (
    <Card className="grid min-h-[40vh] w-full grid-cols-3 gap-2 rounded-[15px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
      
      {/* USD Converter Section */}
      <div className="col-span-1 max-h-[40vh] rounded-xl bg-lightPrimary dark:!bg-navy-700 p-3 flex flex-col items-center justify-center">
        <h4 className="text-md font-semibold text-brand-500 dark:text-white mb-1">
          USD Converter
        </h4>
        <div className="w-full">
          {/* USD Input */}
          <div className="flex flex-col mb-2">
            <label className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-1">
              USD
            </label>
            <input
              type="number"
              placeholder="Enter USD amount"
              value={usdAmount}
              onChange={handleUsdChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 dark:bg-navy-700 dark:border-navy-600 dark:text-white p-1"
            />
          </div>
          {/* Crypto Output */}
          <div className="flex flex-col">
            <label className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-1">
              {coinData.identity || "Crypto"}
            </label>
            <input
              type="text"
              placeholder="Crypto amount"
              value={calculateCrypto()}
              readOnly
              className="block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 dark:bg-navy-600 dark:border-navy-600 dark:text-white p-1"
            />
          </div>
        </div>
      </div>

      {/* Historical Information Section */}
      <div className="col-span-2  flex flex-col p-3 rounded-xl bg-white dark:!bg-navy-800">
        <h5 className="text-lg font-semibold text-navy-700 dark:text-white mb-2">
          Historical Information
        </h5>
        <div className="grid grid-cols-2 gap-2">
          {/* Historical Information Cards */}
          {[
            { label: "Current Price", value: `$${getFormattedPrice(coinData.current_price)}` },
            { 
              label: "Price Change (7d)", 
              value: formatPercentage(coinData.price_change_7d), 
              colorClass: parseFloat(coinData.price_change_7d) >= 0 ? "text-green-500" : "text-red-500" 
            },
            { label: "Price Change (24h)", value: `${getFormattedPrice(coinData.price_change_24h)}` },
            { label: "ROI", value: formatRoi(coinData.roi) },
            { label: "High (24h)", value: `$${getFormattedPrice(coinData.high_24h)}` },
            { label: "Low (24h)", value: `$${getFormattedPrice(coinData.low_24h)}` },
            { label: "All-Time High", value: `$${getFormattedPrice(coinData.ath)}` },
            { label: "ATH Date", value: coinData.ath_date ? new Date(coinData.ath_date).toLocaleDateString() : "N/A" },
            { label: "All-Time Low", value: `$${getFormattedPrice(coinData.atl)}` },
            { label: "ATL Date", value: coinData.atl_date ? new Date(coinData.atl_date).toLocaleDateString() : "N/A" },
          ].map((item, index) => (
            <div key={index} className="flex flex-col p-1 rounded-lg shadow bg-white dark:bg-navy-700 overflow-hidden">
              <span className="text-xs font-normal text-gray-600 dark:text-gray-300">
                {item.label}
              </span>
              <span className={`text-sm font-bold text-navy-700 dark:text-white ${item.colorClass || ""}`}>
                {item.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

// Updated PropTypes for type checking
Upload.propTypes = {
  coinData: PropTypes.shape({
    name: PropTypes.string,
    current_price: PropTypes.number, // Changed from string to number
    price_change_24h: PropTypes.number, // Changed from string to number
    price_change_7d: PropTypes.number, // Changed from string to number
    roi: PropTypes.oneOfType([
      PropTypes.shape({
        times: PropTypes.number,
      }),
      PropTypes.string,
    ]),
    high_24h: PropTypes.number, // Changed from string to number
    low_24h: PropTypes.number, // Changed from string to number
    ath: PropTypes.number, // Changed from string to number
    ath_change_percentage: PropTypes.number, // Changed from string to number
    ath_date: PropTypes.string,
    atl: PropTypes.number, // Changed from string to number
    atl_change_percentage: PropTypes.number, // Changed from string to number
    atl_date: PropTypes.string,
    identity: PropTypes.string, // Assuming you have an 'identity' field
  }).isRequired,
};

export default Upload;
