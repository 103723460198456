// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import Card from "components/card";

function TradingViewWidget({ coinData }) {
  const container = useRef();

  useEffect(() => {
    if (!coinData.symbol) return; // Prevents execution if symbol is undefined

    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      {
        "autosize": true,
        "symbol": "${coinData.symbol}USDT",
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "light",
        "style": "1",
        "locale": "en",
        "toolbar_bg": "#f1f3f6",
        "enable_publishing": false,
        "allow_symbol_change": true,
        "support_host": "https://www.tradingview.com"
      }`;
    container.current.innerHTML = ""; // Clear previous widget instance
    container.current.appendChild(script);
  }, [coinData.symbol]);

  return (
    <Card extra={"w-full h-full p-3"}>
      <h4>
        <span className="text-large font-bold text-bla-600 break-words">
          {coinData.identity} Chart
        </span>
      </h4>

      <div
        className="tradingview-widget-container"
        ref={container}
        style={{ height: "100%", width: "100%" }}
      >
        <div
          className="tradingview-widget-container__widget"
          style={{ height: "100%", width: "100%" }}
        ></div>
      </div>

      <div className="text-center mt-3">
        <a
          href="https://www.tradingview.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline font-medium"
        >
          Track all Markets on TradingView
        </a>
      </div>
    </Card>
  );
}

export default memo(TradingViewWidget);
