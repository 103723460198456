// Bubbles.js
import React, { useRef, useState, useEffect } from 'react';
import ComplexTable from "../default/components/ComplexTable.jsx";

const Bubbles = () => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const measure = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setDimensions({ width: clientWidth, height: clientHeight });
      }
    };

    measure(); // Initial measurement

    // Initialize ResizeObserver to handle container resizing
    const resizeObserver = new ResizeObserver(() => {
      measure();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Cleanup on unmount
    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="container mx-auto p-4 h-screen" // Ensures full viewport height
      style={{ position: 'relative' }} // Necessary for tooltip positioning
    >
      {dimensions.width > 0 && dimensions.height > 0 ? (
        <ComplexTable 
          itemsPerPage={20}
          width={dimensions.width}
          height={dimensions.height} // Adjusting for padding/margins
        />
      ) : (
        <div className="flex items-center justify-center h-full">
          <span className="text-gray-500">Loading...</span>
        </div>
      )}
    </div>
  );
};

export default Bubbles;
