import React, { useRef } from "react";
import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { MdBarChart, MdTrendingUp } from "react-icons/md";
import { columnsDataComplex } from "./variables/columnsData";
import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataComplex from "./variables/tableDataComplex.json";
import useResizeObserver from "./useResizeObserver"; // Adjust the path accordingly

const Dashboard = () => {
  const weeklyRevenueRef = useRef(null);
  const totalSpentRef = useRef(null);

  const weeklyRevenueDimensions = useResizeObserver(weeklyRevenueRef);
  const totalSpentDimensions = useResizeObserver(totalSpentRef);

  return (
    <div>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5">
        <Widget
          icon={<MdTrendingUp className="h-7 w-7" />}
          title={"Top 50 by Volume"}
        />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {/* Weekly Revenue */}
        <div
          ref={weeklyRevenueRef}
          style={{
            height: "500px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            padding: "0",
          }}
        >
          <WeeklyRevenue dimensions={weeklyRevenueDimensions} />
        </div>

        {/* Total Spent */}
        <div
          ref={totalSpentRef}
          style={{
            height: "500px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
            padding: "0",
          }}
        >
          <TotalSpent dimensions={totalSpentDimensions} />
        </div>
      </div>

      {/* Main Content Grid */}
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {/* Check Table - Spans two columns on medium screens and above */}
        <div
          className="md:col-span-2"
          style={{ height: "700px", width: "100%" }}
        >
          <CheckTable /> {/* Top 100 Table */}
        </div>

        {/* Complex Table */}
        <div
          className="md:col-span-2"
          style={{ height: "600px", width: "100%" }}
        >
          <ComplexTable itemsPerPage={20} /> {/* BubbleChart */}
        </div>

        {/* Task Card and Mini Calendar */}
        <div className="md:col-span-2 h-full grid grid-cols-1 gap-5 md:grid-cols-2">
          <TaskCard /> {/* Real-Time Metrics */}
          <MiniCalendar /> {/* Exchange Metrics */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
