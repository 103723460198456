// Heatmaps.js
import React, { useState, useEffect, useRef } from 'react';
import WeeklyRevenue from "../default/components/WeeklyRevenue.jsx";
import TotalSpent from "../default/components/TotalSpent.jsx";

const Heatmaps = () => {
  const weeklyRevenueRef = useRef(null);
  const totalSpentRef = useRef(null);

  const [weeklyRevenueDimensions, setWeeklyRevenueDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [totalSpentDimensions, setTotalSpentDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [itemsPerPage, setItemsPerPage] = useState(20); // Default items per page
  

  useEffect(() => {
    const handleResize = () => {
      if (weeklyRevenueRef.current) {
        const { width, height } = weeklyRevenueRef.current.getBoundingClientRect();
        setWeeklyRevenueDimensions({ width, height });
      }
      if (totalSpentRef.current) {
        const { width, height } = totalSpentRef.current.getBoundingClientRect();
        setTotalSpentDimensions({ width, height });
      }
    };

    // Initial measurement
    handleResize();

    // Update dimensions on window resize
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className="flex flex-col h-screen">


      {/* WeeklyRevenue Component */}
      <div ref={weeklyRevenueRef} className="flex-1 max-h-[70vh]">
        <WeeklyRevenue
          dimensions={weeklyRevenueDimensions}
          itemsPerPage={itemsPerPage}
          
        />
      </div>

      {/* TotalSpent Component */}
      <div ref={totalSpentRef} className="flex-1 max-h-[70vh]">
        <TotalSpent
          dimensions={totalSpentDimensions}
          itemsPerPage={itemsPerPage}
        
        />
      </div>
    </div>
  );
};

export default Heatmaps;
