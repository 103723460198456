import React from "react";

const Marketplace = () => {
  return (
    <div className="mt-8 space-y-12">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-r from-[#4D4DAE] to-[#00008B] text-white">
        <div className="container mx-auto px-6 py-16 text-center">
          <h1 className="text-5xl font-extrabold mb-6">
            CoinHeatMap: Analytics for All
          </h1>
          <p className="text-xl font-light mb-8">
            Democratizing access to blockchain analytics and rewarding you for participation.
          </p>
          <a
            href="https://docs.google.com/document/d/1-jDT5frDbXeij6cb7jH7G8SvagE7e-9f7ZtGQuLMIFw/edit?tab=t.0"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-white text-[#4D4DAE] px-8 py-3 rounded-full font-semibold hover:bg-[#E6E6F3] transition"
          >
            Read our Whitepaper
          </a>
        </div>
      </section>

      {/* Main Content */}
      <main className="container mx-auto px-6 space-y-12">
        {/* Introduction */}
        <section className="bg-[#E6E6F3] rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-[#00008B] mb-4">Introduction</h2>
          <p className="text-[#000061] leading-relaxed">
            People armed with nothing but ambition and an internet connection can compete on equal footing with the most prominent financial institutions. This is the vision of CoinHeatMap.
          </p>
          <p className="text-[#000061] leading-relaxed mt-4">
            Launched in 2020, CoinHeatMap is on a mission to democratize access to blockchain analytics, breaking down the barriers that have long restricted the flow of information in the crypto space. By combining advanced analytical tools with a decentralized framework and a track record of reliably tracking assets that outperform the market, we are creating a platform where everyone has the opportunity to succeed.
          </p>
        </section>

        {/* The Challenges We Face */}
        <section className="bg-[#E6E6F3] rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-[#00008B] mb-4">
            The Challenges We Face
          </h2>
          <ol className="list-decimal ml-5 space-y-6 text-[#000061] leading-relaxed">
            <li>
              <h3 className="text-xl font-semibold text-[#00008B] mb-2">
                Centralized Control
              </h3>
              <ul className="list-disc ml-5 space-y-2">
                <li>
                  <strong>Information Inequality:</strong> Major analytics firms hold a monopoly over crucial data, creating a significant gap between large enterprises and smaller players.
                </li>
                <li>
                  <strong>Transparency Issues:</strong> Centralized entities often lack transparency, leading to mistrust within the community.
                </li>
                <li>
                  <strong>Analytics That Give Back:</strong> Current crypto analytics platforms collect user information and attention but do not reward the user.
                </li>
              </ul>
            </li>
            <li>
              <h3 className="text-xl font-semibold text-[#00008B] mb-2">
                High Costs
              </h3>
              <ul className="list-disc ml-5 space-y-2">
                <li>
                  <strong>Prohibitive Pricing:</strong> Exorbitant subscription fees lock out individual traders and enthusiasts who can't afford premium services.
                </li>
                <li>
                  <strong>Limited Access:</strong> Paywalls prevent the majority from accessing vital market insights, stifling innovation and informed decision-making.
                </li>
              </ul>
            </li>
            <li>
              <h3 className="text-xl font-semibold text-[#00008B] mb-2">
                Narrow Focus
              </h3>
              <ul className="list-disc ml-5 space-y-2">
                <li>
                  <strong>Specialized Silos:</strong> Existing platforms often focus on specific aspects of the market, forcing users to juggle multiple services to get a comprehensive view.
                </li>
                <li>
                  <strong>Integration Gaps:</strong> Lack of holistic tools that integrate data from various sources, including traditional financial markets and social media.
                </li>
              </ul>
            </li>
          </ol>
        </section>

        {/* Our Solution: CoinHeatMap */}
        <section className="bg-[#E6E6F3] rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-[#00008B] mb-4">
            Our Solution: CoinHeatMap
          </h2>
          <p className="text-[#000061] leading-relaxed">
            CoinHeatMap addresses these challenges head-on by creating an inclusive, transparent, and community-driven platform.
          </p>

          <div className="mt-6 space-y-6">
            <div>
              <h3 className="text-2xl font-semibold text-[#4D4DAE] mb-2">
                Decentralized Data Collection
              </h3>
              <ul className="list-disc ml-5 space-y-2 text-[#000061] leading-relaxed">
                <li>
                  <strong>Community Collaboration:</strong> Harnessing the collective power of our users to gather and validate data from blockchains, exchanges, wallets, and more.
                </li>
                <li>
                  <strong>Transparency:</strong> Every piece of data is sourced and verified openly, ensuring trust and reliability.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-2xl font-semibold text-[#4D4DAE] mb-2">
                Advanced Analytics for All
              </h3>
              <ul className="list-disc ml-5 space-y-2 text-[#000061] leading-relaxed">
                <li>
                  <strong>Sophisticated Tools:</strong> Offering institutional-grade analytics including transaction flows, market trends, and sentiment analysis.
                </li>
                <li>
                  <strong>User-Friendly Interface:</strong> Designed for users of all levels, making complex data accessible and understandable.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-2xl font-semibold text-[#4D4DAE] mb-2">
                Incentivized Participation with HMAP Tokens
              </h3>
              <ul className="list-disc ml-5 space-y-2 text-[#000061] leading-relaxed">
                <li>
                  <strong>Use-to-Earn Model:</strong> Rewarding users simply for engaging with the platform—log in, use the tools, which in turn contribute to analytics, and earn HMAP tokens.
                </li>
                <li>
                  <strong>Community Empowerment:</strong> Tokens can be used for governance, accessing premium features, or earning additional rewards through staking.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-2xl font-semibold text-[#4D4DAE] mb-2">
                Community-Driven Governance
              </h3>
              <ul className="list-disc ml-5 space-y-2 text-[#000061] leading-relaxed">
                <li>
                  <strong>Decentralized Autonomous Organization (DAO):</strong> Users have a real say in the platform's development, from proposing new features to voting on important decisions.
                </li>
                <li>
                  <strong>Collective Intelligence:</strong> By involving the community, we tap into a diverse pool of ideas and expertise, fostering innovation.
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Why CoinHeatMap Stands Out */}
        <section className="bg-[#E6E6F3] rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-[#00008B] mb-4">
            Why CoinHeatMap Stands Out
          </h2>
          <ol className="list-decimal ml-5 space-y-6 text-[#000061] leading-relaxed">
            <li>
              <h3 className="text-xl font-semibold text-[#00008B] mb-2">
                Democratizing Access
              </h3>
              <p>
                We believe that access to high-quality analytics should not be a privilege. By making our basic tools free and advanced features accessible through HMAP tokens, we ensure inclusivity.
              </p>
            </li>
            <li>
              <h3 className="text-xl font-semibold text-[#00008B] mb-2">
                Innovative Use-to-Earn Model
              </h3>
              <p>Our pioneering model flips the traditional script:</p>
              <ul className="list-disc ml-5 space-y-2 mt-2">
                <li>
                  <strong>Earn While You Learn:</strong> Users are rewarded for simply using the platform, turning passive consumption into an active earning opportunity.
                </li>
                <li>
                  <strong>Shared Success:</strong> Revenue generated from ads and token swaps is redistributed to users, aligning our growth with the community's prosperity.
                </li>
              </ul>
            </li>
            <li>
              <h3 className="text-xl font-semibold text-[#00008B] mb-2">
                Comprehensive Market Intelligence
              </h3>
              <ul className="list-disc ml-5 space-y-2 mt-2">
                <li>
                  <strong>Holistic Approach:</strong> Integrating data from traditional financial assets, cryptocurrencies, and social media for a 360-degree view of the market.
                </li>
                <li>
                  <strong>Real-Time Insights:</strong> Stay ahead with up-to-the-minute analytics, alerts, and sentiment analysis.
                </li>
              </ul>
            </li>
            <li>
              <h3 className="text-xl font-semibold text-[#00008B] mb-2">
                Empowering the Community
              </h3>
              <ul className="list-disc ml-5 space-y-2 mt-2">
                <li>
                  <strong>DAO Governance:</strong> Users shape the future of CoinHeatMap, ensuring the platform evolves to meet their needs.
                </li>
                <li>
                  <strong>Developer and Intelligence Marketplace:</strong> Open opportunities for developers to contribute, innovate, and be rewarded for market intelligence.
                </li>
              </ul>
            </li>
          </ol>
        </section>

        {/* The HMAP Token */}
        <section className="bg-[#E6E6F3] rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-[#00008B] mb-4">
            The HMAP Token: Fueling Our Ecosystem
          </h2>

          <div className="mt-6">
            <h3 className="text-2xl font-semibold text-[#4D4DAE] mb-2">
              Token Distribution
            </h3>
            <p className="text-[#000061] leading-relaxed">
              <strong>Max Supply:</strong> 500 million HMAP tokens.
            </p>
            <p className="text-[#000061] leading-relaxed mt-2">
              <strong>Allocation:</strong>
            </p>
            <ul className="list-disc ml-5 space-y-2 mt-2 text-[#000061] leading-relaxed">
              <li>40% for ecosystem incentives.</li>
              <li>30% for the founding team and advisors (vested over time to ensure commitment).</li>
              <li>30% available to the public.</li>
            </ul>
          </div>

          <div className="mt-6">
            <h3 className="text-2xl font-semibold text-[#4D4DAE] mb-2">
              Token Utility
            </h3>
            <ul className="list-disc ml-5 space-y-2 text-[#000061] leading-relaxed">
              <li>
                <strong>Governance:</strong> Participate in the DAO, vote on proposals, and influence the platform's direction.
              </li>
              <li>
                <strong>Incentives and Rewards:</strong> Earn tokens through the use-to-earn program, data contributions, and staking.
              </li>
              <li>
                <strong>Access to Premium Features:</strong> Unlock advanced tools and exclusive content.
              </li>
              <li>
                <strong>Staking and Earning:</strong> Stake tokens to earn rewards, promoting network stability and long-term engagement.
              </li>
            </ul>
          </div>
        </section>

        {/* Roadmap */}
        <section className="bg-[#E6E6F3] rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-[#00008B] mb-4">
            Our Roadmap to Success
          </h2>
          <div className="mt-6 space-y-6 text-[#000061] leading-relaxed">
            <div>
              <h3 className="text-2xl font-semibold text-[#4D4DAE] mb-2">
                Phase 1: Foundation Building
              </h3>
              <ul className="list-disc ml-5 space-y-2">
                <li>
                  <strong>Platform Development:</strong> Launch the beta version with core analytics tools.
                </li>
                <li>
                  <strong>Swap</strong> Integrate major DEX aggregator.
                </li>
                <li>
                  <strong>Community Building:</strong> Establish a strong user base through marketing and partnerships.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-2xl font-semibold text-[#4D4DAE] mb-2">
                Phase 2: Expansion and Integration
              </h3>
              <ul className="list-disc ml-5 space-y-2">
                <li>
                  <strong>Feature Enhancements:</strong> Introduce advanced cross-chain and interchain analytics, real-time alerts, and expanded data sources.
                </li>
                <li>
                  <strong>Use-to-Earn Program:</strong> Users will earn HMAP tokens simply by engaging with our analytics platform.
                </li>
                <li>
                  <strong>Cross-Chain Compatibility:</strong> Integrate additional blockchains to widen our reach.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-2xl font-semibold text-[#4D4DAE] mb-2">
                Phase 3: Full Decentralization
              </h3>
              <ul className="list-disc ml-5 space-y-2">
                <li>
                  <strong>DAO Activation:</strong> Transition governance to the community.
                </li>
                <li>
                  <strong>Decentralized Hosting and Data Storage:</strong> Future-proof the platform.
                </li>
                <li>
                  <strong>Developer Marketplace Launch:</strong> Open the platform for third-party developers to contribute and innovate.
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-2xl font-semibold text-[#4D4DAE] mb-2">
                Phase 4: Global Adoption
              </h3>
              <ul className="list-disc ml-5 space-y-2">
                <li>
                  <strong>Strategic Partnerships:</strong> Collaborate with institutions, exchanges, and other platforms.
                </li>
                <li>
                  <strong>Educational Initiatives:</strong> Provide resources to educate users about blockchain analytics and the benefits of decentralization.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-[#000046] text-white text-center py-6">
        <p>&copy; {new Date().getFullYear()} CoinHeatMap. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Marketplace;
