// TotalSpent.jsx
import React, { useEffect, useRef, useState } from "react";
import Card from "components/card";
import * as d3 from "d3";
import TooltipPortal from "./TooltipPortal"; // Ensure the correct path
import PropTypes from "prop-types";

const TotalSpent = ({ dimensions, itemsPerPage = null }) => {
  const treemapRef = useRef(null);
  const tooltipRef = useRef(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [minSpent, setMinSpent] = useState(null);
  const [maxSpent, setMaxSpent] = useState(null);
  const [gradientColors, setGradientColors] = useState({ from: "", to: "" });
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(
    itemsPerPage || 300
  );

  const IMAGE_PADDING = 5; // Padding from top and left
  const TEXT_PADDING = 10; // Padding between image and text
  const MAX_IMAGE_SIZE = 50; // Maximum image size

  const formatCurrency = (number) => {
    if (number === null || number === undefined) {
      return "N/A";
    }
  
    const num = Number(number);
  
    if (isNaN(num)) {
      return "Invalid Number";
    }
  
    if (num >= 1) {
      // For numbers >= 1, use commas and two decimal places
      return num.toLocaleString('en-US', { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
      });
    } else if (num > 0) {
      // For numbers < 1 and > 0, use up to four significant digits
      return num.toPrecision(4);
    } else {
      // For zero or negative numbers, format as is with two decimal places
      return num.toLocaleString('en-US', { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
      });
    }
  };
  
  

  // Fetch data based on currentItemsPerPage
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const beginRange = 0;
        const endRange = currentItemsPerPage;
        const response = await fetch(
          `/api/crypto-data?beginRange=${beginRange}&endRange=${endRange}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          const result = await response.json();
          setData(result);
        } else {
          const text = await response.text();
          throw new Error(`Expected JSON, but got: ${text}`);
        }
      } catch (error) {
        console.error("Error fetching crypto data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentItemsPerPage]);

  // Render treemap when data or dimensions change
  useEffect(() => {
    if (
      data &&
      data.cryptos &&
      treemapRef.current &&
      dimensions &&
      dimensions.width > 0 &&
      dimensions.height > 0
    ) {
      renderTreemap(data, dimensions.height, dimensions.width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dimensions]);

  const renderTreemap = (data, height, width) => {
    // Clear any existing SVG
    d3.select(treemapRef.current).select("svg").remove();

    const adjustedHeight = height;
    const adjustedWidth = width;

    // Extract price change data for color scaling
    const priceChanges = data.cryptos.map((d) =>
      parseFloat(d.price_change_percentage_24h)
    );
    const minPriceChange = d3.min(priceChanges);
    const maxPriceChange = d3.max(priceChanges);

    // Update state
    setMinSpent(minPriceChange);
    setMaxSpent(maxPriceChange);

    if (!data.cryptos || data.cryptos.length === 0) {
      console.error("No data available for treemap.");
      return;
    }

    const hierarchyData = {
      name: "market_cap",
      children: data.cryptos,
    };

    // Define color scale based on 24h price change
    const colorScale = d3
      .scaleLinear()
      .domain([minPriceChange, 0, maxPriceChange])
      .range(["red", "lightgrey", "green"])
      .clamp(true);

    // Update gradient colors in state
    setGradientColors({
      from: colorScale(minPriceChange),
      to: colorScale(maxPriceChange),
    });

    const root = d3
      .hierarchy(hierarchyData)
      .sum((d) => +d.market_cap)
      .sort((a, b) => b.value - a.value);

    d3.treemap().size([adjustedWidth, adjustedHeight]).padding(1)(root);

    const svg = d3
      .select(treemapRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${adjustedWidth} ${adjustedHeight}`)
      .attr("preserveAspectRatio", "none")
      .style("font-family", "Avenir")
      .style("font-size", "12px"); // Adjusted for better readability

    const g = svg.append("g");

    // Add zoom functionality (optional, similar to WeeklyRevenue)
    const zoom = d3
      .zoom()
      .scaleExtent([1, 5])
      .translateExtent([
        [0, 0],
        [adjustedWidth, adjustedHeight],
      ])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      });

    svg.call(zoom);

    // Function to calculate image size based on node dimensions
    const calculateImageSize = (d) => {
      const nodeWidth = d.x1 - d.x0;
      const nodeHeight = d.y1 - d.y0;
      const minDimension = Math.min(nodeWidth, nodeHeight);
      return Math.min(minDimension * 0.4, MAX_IMAGE_SIZE); // Limits the image size to MAX_IMAGE_SIZE
    };

    const nodes = g
      .selectAll("g")
      .data(root.leaves())
      .enter()
      .append("g")
      .attr("transform", (d) => `translate(${d.x0},${d.y0})`)
      .style("cursor", "pointer")
      .on("click", (event, d) => {
        window.location.href = `/dashboard/admin/coins/${encodeURIComponent(d.data.name)}`;
      })
      .on("mouseover", function (event, d) {
        d3.select(this)
          .select("rect")
          .attr("stroke", "yellow")
          .attr("stroke-width", 1);

        tooltipRef.current.style.visibility = "visible";
        tooltipRef.current.innerHTML = `
          <strong>${d.data.identity} (${d.data.symbol})</strong><br>
          Rank by Market Cap: <strong>${d.data.market_cap_rank}</strong><br>
          Price: <strong>$${formatCurrency(d.data.current_price)}</strong><br>
          Price Change (24h): <strong style="color: ${
            d.data.price_change_percentage_24h >= 0 ? "green" : "red"
          };">
            ${d.data.price_change_percentage_24h >= 0 ? "▲" : "▼"} 
            ${Math.abs(
              Number(d.data.price_change_percentage_24h)
            ).toFixed(2)}%
          </strong><br>
          Market Cap: <strong>${Math.round(
            d.data.market_cap
          ).toLocaleString()}</strong><br>
          Circulating Supply: <strong>${Math.round(
            d.data.circulating_supply
          ).toLocaleString()}</strong><br>
          Total Supply: <strong>${Math.round(
            d.data.total_supply
          ).toLocaleString()}</strong><br>
          24 Hour Volume: <strong>${Math.round(
            d.data.total_volume
          ).toLocaleString()}</strong>
        `;
      })
      .on("mousemove", (event) => {
        tooltipRef.current.style.top = `${event.pageY + 15}px`;
        tooltipRef.current.style.left = `${event.pageX + 15}px`;
      })
      .on("mouseout", function () {
        d3.select(this)
          .select("rect")
          .attr("stroke", "white")
          .attr("stroke-width", 0.5);
        tooltipRef.current.style.visibility = "hidden";
      });

    // Append rectangles
    nodes
      .append("rect")
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0)
      .attr("fill", (d) => colorScale(d.data.price_change_percentage_24h))
      .style("stroke", "white")
      .style("stroke-width", "0.5px");

    // Append images
    nodes
      .append("image")
      .attr("xlink:href", (d) => d.data.logo_url)
      .attr("width", (d) => calculateImageSize(d))
      .attr("height", (d) => calculateImageSize(d))
      .attr("x", IMAGE_PADDING)
      .attr("y", IMAGE_PADDING)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .style("pointer-events", "none");

    // Append text
    nodes
      .append("text")
      .attr("x", IMAGE_PADDING)
      .attr("y", (d) => IMAGE_PADDING + calculateImageSize(d) + TEXT_PADDING)
      .selectAll("tspan")
      .data((d) => [
        `${d.data.identity} (${d.data.symbol})`,
        `Price: $${parseFloat(d.data.current_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,

        `Change: ${
          d.data.price_change_percentage_24h >= 0 ? "▲" : "▼"
        } ${Math.abs(Number(d.data.price_change_percentage_24h)).toFixed(2)}%`,
      ])
      .enter()
      .append("tspan")
      .attr("x", IMAGE_PADDING)
      .attr("dy", (d, i) => (i === 0 ? 0 : 14)) // Adjust vertical spacing as needed
      .text((d) => d)
      .style("font-size", (d) => `${Math.min(12, 12)}px`)
      .style(
        "font-weight",
        (d) =>
          d.startsWith("Price") || d.startsWith("Change") ? "normal" : "bold"
      )
      .style("fill", "white")
      .attr("pointer-events", "none");

    // Preventing Overflow with Clipping Paths
    const defs = svg.append("defs");

    root.leaves().forEach((d) => {
      defs
        .append("clipPath")
        .attr("id", `clip-${d.data.identity}`)
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", d.x1 - d.x0)
        .attr("height", d.y1 - d.y0);
    });

    // Apply clipping to each node's group
    nodes.attr("clip-path", (d) => `url(#clip-${d.data.identity})`);
  };

  return (
    <Card extra="flex flex-col bg-white w-full h-full rounded-3xl py-6 px-2 text-center">
      <div className="mb-auto flex items-center justify-between px-0">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">
          Market Cap Heatmap
        </h2>
        {minSpent !== null && maxSpent !== null && (
          <div className="flex items-center space-x-2">
            {/* Gradient Bar */}
            <div className="relative">
              <div
                className="w-40 h-4 rounded"
                style={{
                  background: `linear-gradient(to right, ${gradientColors.from}, ${gradientColors.to})`,
                }}
              ></div>
              {/* Overlay Labels */}
              <div className="absolute top-0 left-0 w-full flex justify-between text-xs">
                <span>%Loss</span>
                <span>%Gain</span>
              </div>
            </div>
          </div>
        )}
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-full">
          <p>Loading...</p>
        </div>
      ) : error ? (
        <div className="text-red-500">Error: {error}</div>
      ) : (
        <>
          <div
            className="relative flex-grow overflow-hidden"
            ref={treemapRef}
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* SVG treemap will be appended here by D3 */}

            {/* Items Per Page Toggle */}
            {itemsPerPage !== null && (
              <div className="absolute bottom-4 left-4 bg-white bg-opacity-80 p-2 rounded shadow">
                <label htmlFor="items-per-page" className="mr-2 text-sm">
                  Items per page:
                </label>
                <select
                  id="items-per-page"
                  value={currentItemsPerPage}
                  onChange={(e) =>
                    setCurrentItemsPerPage(Number(e.target.value))
                  }
                  className="border rounded px-2 py-1 text-sm"
                >
                  {/* Define your desired options here */}
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            )}
          </div>
          <TooltipPortal tooltipRef={tooltipRef} />
        </>
      )}
    </Card>
  );
};

// Define prop types for better type checking
TotalSpent.propTypes = {
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  itemsPerPage: PropTypes.number, // Can be null
};

export default TotalSpent;
