// ComplexTable.jsx
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Card from "components/card"; // Ensure this path is correct
import * as d3 from "d3"; // Import D3
import TooltipPortal from "./TooltipPortal"; // Adjust the path as necessary
import PropTypes from "prop-types"; // For prop type validation

// Utility function to format numbers
const formatNumber = (value, decimals = 2) => {
  let number = Number(value);
  if (isNaN(number)) return "N/A";
  if (number > 1) {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  } else if (number > 0) {
    return number.toFixed(decimals + 8); // For values <= 1
  }
  return "N/A"; // For 'N/A' or invalid numbers
};

const ComplexTable = ({ itemsPerPage, passedH, passedW }) => {
  const [allCryptoData, setAllCryptoData] = useState([]);
  const [cryptoData, setCryptoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [showGainers, setShowGainers] = useState(true); // State to toggle between gainers and losers

  const bubbleChartRef = useRef(null); // Reference to the bubble chart container
  const tooltipRef = useRef(null); // Reference to the tooltip

  // State for items per page (only if itemsPerPage prop is provided)
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(
    typeof itemsPerPage === "number" ? itemsPerPage : 20 // Default to 20 if not provided
  );
  const [beginRange] = useState(0); // Fixed beginRange
  const [endRange] = useState(1000); // Fixed endRange to fetch all data

  // Define a size threshold for small bubbles
  const SMALL_BUBBLE_THRESHOLD = 30; // Adjust based on sizeScale range

  // Define a function to determine maximum bubble size based on number of items
  const getMaxBubbleSize = (numItems, width, height) => {
    if (numItems <= 10) {
      return Math.min(width, height) / 3; // Larger bubbles for very few items
    } else if (numItems <= 20) {
      return Math.min(width, height) / 6; // Moderate size
    } else {
      return Math.min(width, height) / 10; // Smaller size for more items
    }
  };

  // Fetch data from the API once on component mount
  useEffect(() => {
    const fetchCryptoData = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await axios.get(
          `/api/crypto-data?beginRange=${beginRange}&endRange=${endRange}`
        );

        const { cryptos } = response.data;

        // Parse and validate the data
        const parsedData = cryptos
          .map((crypto) => ({
            identity: crypto.identity,
            symbol: crypto.symbol,
            name: crypto.name,
            market_cap_rank: Number(crypto.market_cap_rank) || 0,
            current_price:
              crypto.current_price !== "N/A"
                ? Number(crypto.current_price)
                : "N/A",
            price_change_percentage_24h:
              crypto.price_change_percentage_24h !== "N/A"
                ? Number(crypto.price_change_percentage_24h)
                : "N/A",
            market_cap:
              crypto.market_cap !== "N/A" ? Number(crypto.market_cap) : "N/A",
            social_volume_24h:
              crypto.social_volume_24h !== "N/A"
                ? Number(crypto.social_volume_24h)
                : 0, // Default to 0 if not available
            logo_url: crypto.logo_url || "", // Default to empty string if not available
            circulating_supply:
              crypto.circulating_supply !== "N/A"
                ? Number(crypto.circulating_supply)
                : "N/A",
            total_supply:
              crypto.total_supply !== "N/A"
                ? Number(crypto.total_supply)
                : "N/A",
            total_volume:
              crypto.total_volume !== "N/A"
                ? Number(crypto.total_volume)
                : "N/A",
          }))
          // Filter out entries without valid price change
          .filter(
            (crypto) =>
              crypto.price_change_percentage_24h !== "N/A" &&
              !isNaN(crypto.price_change_percentage_24h)
          );

        console.log("Fetched and Parsed Data:", parsedData); // Debug log

        setAllCryptoData(parsedData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching crypto data:", err);
        setError(true);
        setLoading(false);
      }
    };

    fetchCryptoData();
  }, [beginRange, endRange]);

  // Update cryptoData based on showGainers and currentItemsPerPage
  useEffect(() => {
    if (!allCryptoData.length) return;

    // Filter the data based on showGainers
    const filteredData = showGainers
      ? allCryptoData.filter(
          (crypto) => crypto.price_change_percentage_24h > 0
        )
      : allCryptoData.filter(
          (crypto) => crypto.price_change_percentage_24h < 0
        );

    // Sort the filtered data
    const sortedData = [...filteredData].sort((a, b) =>
      showGainers
        ? b.price_change_percentage_24h - a.price_change_percentage_24h // Descending for gainers
        : a.price_change_percentage_24h - b.price_change_percentage_24h // Ascending for losers
    );

    // Slice based on currentItemsPerPage or default to 20
    const itemsToShow =
      typeof currentItemsPerPage === "number" ? currentItemsPerPage : 20;
    const slicedData = sortedData.slice(0, itemsToShow);

    console.log(
      `Displaying Top ${itemsToShow} ${showGainers ? "Gainers" : "Losers"}:`,
      slicedData
    ); // Debug log

    setCryptoData(slicedData);
  }, [allCryptoData, showGainers, currentItemsPerPage]);

  // Render bubble chart when cryptoData changes
  useEffect(() => {
    if (!cryptoData.length || !bubbleChartRef.current) return;

    const container = bubbleChartRef.current;

    // Ensure the container has defined dimensions
    const width = passedW || container.clientWidth;
    const height = passedH || container.clientHeight;

    // Check if width and height are greater than 0
    if (width === 0 || height === 0) {
      console.warn("Bubble chart container has zero width or height.");
      return;
    }

    // Initial render of the bubble chart
    renderBubbleChart(cryptoData, height, width, "price_change");

    // Setup ResizeObserver to handle container resizing
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width: newWidth, height: newHeight } = entry.contentRect;
        if (newWidth === 0 || newHeight === 0) {
          console.warn("Resized container has zero width or height.");
          continue;
        }
        renderBubbleChart(cryptoData, newHeight, newWidth, "price_change");
      }
    });

    resizeObserver.observe(container);

    // Cleanup on unmount
    return () => {
      resizeObserver.unobserve(container);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cryptoData, passedH, passedW]);

  const renderBubbleChart = (data, height, width, filter) => {
    console.log("Rendering Bubble Chart with data:", data);

    // Clear any existing content
    const container = d3.select(bubbleChartRef.current).html("");

    // Set the dimensions and create the SVG with viewBox for responsiveness
    const svg = container
      .append("svg")
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .style("width", "100%")
      .style("height", "100%")
      .attr("text-anchor", "middle")
      .style("font-size", "20px"); // Reduced base font size

    // Define a color scale for the fill color of bubbles based on price change
    const priceChanges = data.map((d) => d.price_change_percentage_24h);
    const minPriceChange = d3.min(priceChanges);
    const maxPriceChange = d3.max(priceChanges);

    console.log("Price Change Range:", minPriceChange, maxPriceChange); // Debug log

    const colorScale = d3
      .scaleLinear()
      .domain([minPriceChange, 0, maxPriceChange])
      .range(["red", "lightgrey", "green"])
      .clamp(true);

    // Size the bubbles based on the 'filter'
    data.forEach((d) => {
      switch (filter) {
        case "market_cap":
          d.value = d.market_cap || 0;
          break;
        case "price_change":
          d.value = Math.abs(d.price_change_percentage_24h) || 0;
          break;
        case "trending":
          d.value = d.social_volume_24h || 0;
          break;
        default:
          d.value = d.market_cap || 0;
      }
    });

    // Normalize 'value' using a logarithmic scale to handle wide-ranging data
    const logScale = d3
      .scaleLog()
      .domain([
        Math.max(1, d3.min(data, (d) => d.value)), // Avoid log(0)
        d3.max(data, (d) => d.value),
      ])
      .range([15, 90]); // Increased range for larger bubbles

    data.forEach((d) => {
      d.normalizedValue = logScale(d.value);
    });

    // Determine maximum bubble size based on number of items
    const maxBubbleSize = getMaxBubbleSize(data.length, width, height);

    // Define sizeScale based on normalizedValue with dynamic range
    const sizeScale = d3
      .scaleSqrt()
      .domain(d3.extent(data, (d) => d.normalizedValue))
      .range([15, maxBubbleSize]); // Dynamic maximum size

    // Add padding to collision radius
    const padding = 10; // Increased padding for larger bubbles

    // Create the force simulation with stabilized parameters
    const simulation = d3
      .forceSimulation(data)
      .force("center", d3.forceCenter(width / 2, height / 2))
      .force(
        "collide",
        d3
          .forceCollide()
          .radius((d) => sizeScale(d.normalizedValue) + padding)
      )
      .force("charge", d3.forceManyBody().strength(-40)) // Adjusted repulsion
      .force("x", d3.forceX(width / 2).strength(0.05))
      .force("y", d3.forceY(height / 2).strength(0.05))
      .alphaDecay(0.03) // Slower decay for stability
      .velocityDecay(0.6) // Higher velocity decay to reduce movement over time
      .on("tick", ticked);

    // Create bubble groups
    const bubbleGroups = svg
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      )
      .style("cursor", "pointer")
      .on("click", (event, d) => {
        window.location.href = `/dashboard/admin/coins/${d.name}`;
      });

    // Append circles to each bubble group
    bubbleGroups
      .append("circle")
      .attr("r", (d) => sizeScale(d.normalizedValue))
      .style("fill", (d) =>
        d.price_change_percentage_24h !== "N/A"
          ? colorScale(d.price_change_percentage_24h)
          : "gray"
      )
      .style("opacity", 0.7)
      .style("stroke", "black")
      .style("stroke-width", 1);

    // Append images to each bubble group, ensuring they are centered
    bubbleGroups
      .append("image")
      .attr("href", (d) => d.logo_url)
      .attr("x", (d) => -sizeScale(d.normalizedValue) * 0.25) // Reduced from 0.3 to 0.25 for smaller images
      .attr("y", (d) => -sizeScale(d.normalizedValue) * 0.25) // Reduced from 0.3 to 0.25
      .attr("height", (d) => sizeScale(d.normalizedValue) * 0.5) // Reduced from 0.6 to 0.5
      .attr("width", (d) => sizeScale(d.normalizedValue) * 0.5) // Reduced from 0.6 to 0.5
      .attr("preserveAspectRatio", "xMidYMid meet"); // Maintain aspect ratio

    // Truncate text if it exceeds the character limit
    const MAX_TEXT_LENGTH = 10; // Adjust as needed

    // Calculate font size based on bubble size
    const calculateFontSize = (d) => {
      return Math.max(8, sizeScale(d.normalizedValue) / 3.5) + "px"; // Further reduced scaling factor
    };

    // Append texts and conditionally display icon
    bubbleGroups.each(function (d) {
      const group = d3.select(this);

      // Determine if the bubble is small
      const isSmall = sizeScale(d.normalizedValue) < SMALL_BUBBLE_THRESHOLD;

      // Identity Text or Icon
      if (d.symbol.length > MAX_TEXT_LENGTH) {
        // Append Icon with responsive sizing
        group
          .append("foreignObject")
          .attr("x", -sizeScale(d.normalizedValue) * 0.2) // Adjusted from 0.25 to 0.2
          .attr("y", -sizeScale(d.normalizedValue) * 0.4) // Adjusted from 0.5 to 0.4
          .attr("width", sizeScale(d.normalizedValue) * 0.4) // Adjusted from 0.5 to 0.4
          .attr("height", sizeScale(d.normalizedValue) * 0.4) // Adjusted from 0.5 to 0.4
          .append("xhtml:div")
          .style("width", "100%")
          .style("height", "100%")
          .style("display", "flex")
          .style("align-items", "center")
          .style("justify-content", "center")
          .append("span")
          .attr("class", "info-icon")
          .style("color", "white")
          .style("fontSize", calculateFontSize(d))
          .style("cursor", "pointer")
          .html(
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="100%" height="100%"><path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm1 17h-2v-6h2v6zm1-8h-4V7h4v4z"/></svg>'
          ) // Adjusted SVG size to fit within the foreignObject
          .on("mouseover", (event) => {
            showTooltip(d, event);
          })
          .on("mouseout", () => {
            hideTooltip();
          });
      } else {
        // Append Identity Text with responsive sizing
        group
          .append("text")
          .attr("x", 0) // Centered horizontally
          .attr("y", -sizeScale(d.normalizedValue) * 0.5) // Positioned above the image
          .attr("font-size", calculateFontSize(d))
          .attr("dominant-baseline", "middle") // Vertically center the text
          .style("font-family", "Avenir")
          .style("font-weight", "bold")
          .style("text-anchor", "middle")
          .text(
            d.symbol.length > MAX_TEXT_LENGTH
              ? d.symbol.slice(0, MAX_TEXT_LENGTH) + "..."
              : d.symbol
          )
          .style("fill", "white");
      }

      if (!isSmall) {
        // Current Price Text
        if (d.current_price !== "N/A") {
          group
            .append("text")
            .attr("x", 0)
            .attr("y", sizeScale(d.normalizedValue) * 0.6) // Shifted from 0.7 to 0.6
            .attr("font-size", parseFloat(calculateFontSize(d)) * 0.7 + "px") // Reduced multiplier
            .attr("dominant-baseline", "middle") // Vertically center the text
            .style("font-family", "Avenir")
            .style("text-anchor", "middle")
            .text(`$${formatNumber(d.current_price)}`)
            .style("fill", "white");
        } else {
          group
            .append("text")
            .attr("x", 0)
            .attr("y", sizeScale(d.normalizedValue) * 0.3) // Shifted up
            .attr("font-size", parseFloat(calculateFontSize(d)) * 0.6 + "px") // Reduced multiplier
            .attr("dominant-baseline", "middle") // Vertically center the text
            .style("font-family", "Avenir")
            .style("text-anchor", "middle")
            .text("N/A")
            .style("fill", "white");
        }

        // Price Change Text
        if (d.price_change_percentage_24h !== "N/A") {
          group
            .append("text")
            .attr("x", 0)
            .attr("y", sizeScale(d.normalizedValue) * 0.9) // Shifted from 1.0 to 0.9
            .attr("font-size", parseFloat(calculateFontSize(d)) * 0.6 + "px") // Reduced multiplier
            .attr("dominant-baseline", "middle") // Vertically center the text
            .style("font-family", "Avenir")
            .style("text-anchor", "middle")
            .text(
              `${d.price_change_percentage_24h > 0 ? "↑" : "↓"} ${Math.abs(
                d.price_change_percentage_24h
              ).toFixed(2)}%`
            )
            .style("fill", d.price_change_percentage_24h >= 0 ? "green" : "red");
        } else {
          group
            .append("text")
            .attr("x", 0)
            .attr("y", sizeScale(d.normalizedValue) * 0.6) // Shifted up
            .attr("font-size", parseFloat(calculateFontSize(d)) * 0.6 + "px") // Reduced multiplier
            .attr("dominant-baseline", "middle") // Vertically center the text
            .style("font-family", "Avenir")
            .style("text-anchor", "middle")
            .text("N/A")
            .style("fill", "white");
        }
      }
    });

    // Simulation tick function
    function ticked() {
      bubbleGroups.attr("transform", function (d) {
        // Boundary constraints with padding
        d.x = Math.max(
          sizeScale(d.normalizedValue) + padding,
          Math.min(width - sizeScale(d.normalizedValue) - padding, d.x)
        );
        d.y = Math.max(
          sizeScale(d.normalizedValue) + padding,
          Math.min(height - sizeScale(d.normalizedValue) - padding, d.y)
        );
        return `translate(${d.x},${d.y})`;
      });
    }

    // Drag functions
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    // Tooltip Functions
    const showTooltip = (d, event) => {
      if (tooltipRef.current) {
        const [x, y] = d3.pointer(event, container);
        tooltipRef.current.innerHTML = `
          <strong>${d.name} (${d.symbol})</strong><br/>
          Rank by Market Cap: <strong>${d.market_cap_rank}</strong><br/>
          Price: <strong>$${formatNumber(d.current_price)}</strong><br/>
          Price Change (24h): <strong style="color: ${
            d.price_change_percentage_24h >= 0 ? "green" : "red"
          };">
            ${d.price_change_percentage_24h >= 0 ? "▲" : "▼"} 
            ${Math.abs(Number(d.price_change_percentage_24h)).toFixed(2)}%
          </strong><br/>
          Market Cap: <strong>${
            d.market_cap !== "N/A"
              ? Math.round(d.market_cap).toLocaleString()
              : "N/A"
          }</strong><br/>
          Circulating Supply: <strong>${
            d.circulating_supply !== "N/A"
              ? Math.round(d.circulating_supply).toLocaleString()
              : "N/A"
          }</strong><br/>
          Total Supply: <strong>${
            d.total_supply !== "N/A"
              ? Math.round(d.total_supply).toLocaleString()
              : "N/A"
          }</strong><br/>
          24 Hour Volume: <strong>${
            d.total_volume !== "N/A"
              ? Math.round(d.total_volume).toLocaleString()
              : "N/A"
          }</strong>
        `;
        tooltipRef.current.style.left = `${x + 10}px`; // Position tooltip near cursor
        tooltipRef.current.style.top = `${y + 10}px`;
        tooltipRef.current.style.visibility = "visible";
      }
    };

    const hideTooltip = () => {
      if (tooltipRef.current) {
        tooltipRef.current.style.visibility = "hidden";
      }
    };
  };

  // Handle items per page change without altering the API call
  const handleItemsPerPageChange = (e) => {
    const selectedValue = Number(e.target.value);
    setCurrentItemsPerPage(selectedValue);
  };

  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto flex flex-col"}>
      {/* Header Section */}
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Top {currentItemsPerPage || 20} Crypto {showGainers ? "Gainers" : "Losers"} (24H Change)
        </div>
        {/* Toggle Switch */}
        <div className="flex items-center">
          <span className="mr-2 text-gray-700 dark:text-gray-300">Losers</span>
          <label htmlFor="toggleGainers" className="inline-flex relative items-center cursor-pointer">
            <input
              type="checkbox"
              id="toggleGainers"
              className="sr-only peer"
              checked={showGainers}
              onChange={() => setShowGainers(!showGainers)}
            />
            {/* Background Switch */}
            <div className="w-11 h-6 bg-gray-200 rounded-full peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:bg-blue-600 transition-colors duration-300 ease-in-out"></div>
            {/* Toggle Circle */}
            <div className="absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
            <span className="ml-2 text-gray-700 dark:text-gray-300">Gainers</span>
          </label>
        </div>
      </div>

      {/* Loading State */}
      {loading && (
        <div className="mt-8 text-center text-gray-500">Loading...</div>
      )}

      {/* Error State */}
      {error && (
        <div className="mt-8 text-center text-red-500">
          Failed to load cryptocurrency data. Please try again later.
        </div>
      )}

      {/* Bubble Chart Section */}
      {!loading && !error && cryptoData.length > 0 && (
        <div
          ref={bubbleChartRef}
          id="bubbleChart"
          className="mt-8 w-full flex-1 bg-white dark:bg-gray-800 rounded shadow overflow-hidden" // flex-1 to take available space
          style={{ minHeight: "300px" }} // Ensure the div has a minimum height
        ></div>
      )}

      {/* No Data State */}
      {!loading && !error && cryptoData.length === 0 && (
        <div className="mt-8 text-center text-gray-500">
          No {showGainers ? "Gainers" : "Losers"} available to display.
        </div>
      )}

      {/* Items Per Page Toggle */}
      {typeof itemsPerPage === "number" && (
        <div className="mt-4 flex justify-start">
          <label htmlFor="itemsPerPage" className="mr-2 text-gray-700 dark:text-gray-300">
            Items per page:
          </label>
          <select
            id="itemsPerPage"
            value={currentItemsPerPage}
            onChange={handleItemsPerPageChange}
            className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded px-2 py-1"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      )}

      {/* Tooltip Portal */}
      <TooltipPortal tooltipRef={tooltipRef} />
    </Card>
  );
};

// PropTypes for validation
ComplexTable.propTypes = {
  itemsPerPage: PropTypes.number, // Optional prop
  passedH: PropTypes.number,       // Ensure these are correctly typed
  passedW: PropTypes.number,
};

// Default Props
ComplexTable.defaultProps = {
  itemsPerPage: null, // Set a sensible default if not provided
  passedH: null,     // Example default height
  passedW: null,     // Example default width
};

export default ComplexTable;
