// Project.js

import React, { useEffect, useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import Card from "components/card";
import parse from "html-react-parser";

const Project = ({ coinData }) => {
  const [cryptoData, setCryptoData] = useState(null);
  const [error, setError] = useState(null);

  const formatPercentage = (percentage) => {
    return percentage !== null && percentage !== undefined
      ? `${parseFloat(percentage).toFixed(2)}%`
      : "N/A";
  };

  useEffect(() => {
    if (!coinData || !coinData.name) return;

    const fetchCryptoData = async () => {
      try {
        // Fetch data from the API
        const response = await fetch(`/api/metadata/?identity=${coinData.name}`);
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('Cryptocurrency not found.');
          } else {
            throw new Error(`Error: ${response.status}`);
          }
        }
        const data = await response.json();
        console.log("Fetched Crypto Data:", data); // Console log the response

        if (Array.isArray(data) && data.length > 0) {
          setCryptoData(data[0]);
        } else {
          setCryptoData(null);
        }
      } catch (err) {
        console.error("Failed to fetch crypto data:", err);
        setError(err.message);
      }
    };

    fetchCryptoData();
  }, [coinData]);

  if (error) {
    return (
      <Card extra={"w-full p-4 h-full"}>
        <div className="mb-8 w-full">
          <h4 className="text-xl font-bold text-red-600">
            Error
          </h4>
          <p className="mt-2 text-base text-gray-600">
            {error}
          </p>
        </div>
      </Card>
    );
  }

  if (!cryptoData) {
    return (
      <Card extra={"w-full p-4 h-full"}>
        <div className="mb-8 w-full">
          <h4 className="text-xl font-bold text-gray-700">
            Loading...
          </h4>
        </div>
      </Card>
    );
  }

  // Utility function to format numbers with commas
  const formatNumber = (num) => {
    if (typeof num !== 'number') return num;
    return num.toLocaleString();
  };

  // Function to filter out fields that are null, 0, or empty strings
  const getFilteredData = (data) => {
    const filtered = {};
    for (const key in data) {
      if (
        key === "market_cap_rank" ||
        key === "status_updates" ||
        key === "last_updated"
      ) continue; // Exclude less relevant fields

      const value = data[key];
      if (
        value === null ||
        value === 0 ||
        value === '0' ||
        (typeof value === 'string' && value.trim() === "")
      ) continue;

      filtered[key] = value;
    }
    return filtered;
  };

  const filteredData = getFilteredData(cryptoData);

  // Function to extract and safely parse the English description
  const getEnglishDescription = (description) => {
    if (description && description.en) {
      return parse(description.en); // Safely parse and render HTML
    }
    return "No description available.";
  };

  // Function to format key titles
  const formatTitle = (key) => {
    return key
      .replace(/_/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase());
  };

  // Function to render links
  const renderLinks = () => {
    const links = cryptoData.links || {};
    return (
      <div className="mt-4 space-y-2">
        {/* Homepage */}
        {links.homepage && links.homepage[0] && (
          <div>
            <span className="font-medium text-gray-700">Homepage:</span>
            <a
              href={links.homepage[0]}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2 text-brand-500 hover:underline"
            >
              Visit Homepage
            </a>
          </div>
        )}

        {/* Official Forum */}
        {links.official_forum_url && links.official_forum_url[0] && (
          <div>
            <span className="font-medium text-gray-700">Official Forum:</span>
            <a
              href={links.official_forum_url[0]}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2 text-brand-500 hover:underline"
            >
              Visit Forum
            </a>
          </div>
        )}

        {/* Blockchain Sites */}
        {links.blockchain_site && Array.isArray(links.blockchain_site) && links.blockchain_site.length > 0 && (
          <div>
            <span className="font-medium text-gray-700">Blockchain Sites:</span>
            <div className="ml-2">
              {links.blockchain_site
                .filter(site => site) // Remove empty strings
                .map((site, index) => (
                  <a
                    key={index}
                    href={site}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block text-brand-500 hover:underline"
                  >
                    {site}
                  </a>
                ))}
            </div>
          </div>
        )}

        {/* Repositories */}
        {links.repos_url && links.repos_url.github && Array.isArray(links.repos_url.github) && links.repos_url.github.length > 0 && (
          <div>
            <span className="font-medium text-gray-700">Repositories:</span>
            <div className="ml-2">
              {links.repos_url.github.map((repo, index) => (
                <a
                  key={index}
                  href={repo}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-brand-500 hover:underline"
                >
                  {repo}
                </a>
              ))}
            </div>
          </div>
        )}

        {/* Subreddit */}
        {links.subreddit_url && (
          <div>
            <span className="font-medium text-gray-700">Subreddit:</span>
            <a
              href={links.subreddit_url}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2 text-brand-500 hover:underline"
            >
              Visit Subreddit
            </a>
          </div>
        )}

        {/* Twitter */}
        {links.twitter_screen_name && (
          <div>
            <span className="font-medium text-gray-700">X:</span>
            <a
              href={`https://x.com/${links.twitter_screen_name}`}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2 text-brand-500 hover:underline"
            >
              @{links.twitter_screen_name}
            </a>
          </div>
        )}

        {/* Telegram */}
        {links.telegram_channel_identifier && (
          <div>
            <span className="font-medium text-gray-700">Telegram:</span>
            <a
              href={`https://t.me/${links.telegram_channel_identifier}`}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2 text-brand-500 hover:underline"
            >
              Join Telegram
            </a>
          </div>
        )}
      </div>
    );
  };

  return (
    <Card extra={"w-full p-6 max-h-[47vh] overflow-y-auto"}>
      {/* Header Section 
      <div className="mb-6 flex items-center">
        {cryptoData.image && cryptoData.image.large && (
          <img
            className="h-20 w-20 rounded-full"
            src={cryptoData.image.large}
            alt={cryptoData.name}
            loading="lazy"
          />
        )}
        <div className="ml-6">
          <h2 className="text-3xl font-bold text-navy-700">
            {cryptoData.name || "Crypto Details"}
          </h2>
          <p className="mt-1 text-sm text-gray-600">
            {cryptoData.symbol.toUpperCase()} • ID: {cryptoData.id}
          </p>
        </div>
        <div className="ml-auto flex items-center text-gray-600">
         
        </div>
      </div>*/}

      {/* Description Section */}
      <div className="mb-6">
        <h3 className="text-2xl font-semibold text-navy-700">Description</h3>
        <p className="mt-2 text-gray-600">
          {getEnglishDescription(cryptoData.description)}
        </p>
      </div>

      {/* Categories Section */}
      {cryptoData.categories && Array.isArray(cryptoData.categories) && cryptoData.categories.length > 0 && (
        <div className="mb-6">
          <h3 className="text-2xl font-semibold text-navy-700">Categories</h3>
          <div className="mt-2 flex flex-wrap gap-2">
            {cryptoData.categories.map((category, index) => (
              <span
                key={index}
                className="px-2 py-1 bg-gray-200 rounded-full text-sm"
              >
                {category}
              </span>
            ))}
          </div>
        </div>
      )}

      {/* Community Data Section */}
      {cryptoData.community_data && (
        <div className="mb-6">
          <h3 className="text-2xl font-semibold text-navy-700">Community Data</h3>
          <div className="mt-2 grid grid-cols-2 md:grid-cols-3 gap-4 text-gray-600">
            {Object.entries(cryptoData.community_data).map(([key, value]) => {
              // Exclude 'telegram_channel' key
              if (
                key === 'telegram_channel' || // Exclude Telegram Channel
                value === null ||
                value === 0 ||
                value === '0' ||
                (typeof value === 'string' && value.trim() === "")
              ) return null;

              return (
                <div key={key}>
                  <span className="font-medium">{formatTitle(key)}:</span>
                  <span className="ml-2">{formatNumber(value)}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Developer Data Section */}
      {cryptoData.developer_data && (
        <div className="mb-6">
          <h3 className="text-2xl font-semibold text-navy-700">Developer Data</h3>
          <div className="mt-2 grid grid-cols-2 md:grid-cols-3 gap-4 text-gray-600">
            {Object.entries(cryptoData.developer_data).map(([key, value]) => {
              if (
                value === null ||
                value === 0 ||
                value === '0' ||
                (typeof value === 'object' && Object.keys(value).length === 0)
              ) return null;

              // For nested objects like repos_url
              if (typeof value === 'object' && !Array.isArray(value)) {
                return (
                  <div key={key}>
                    <span className="font-medium">{formatTitle(key)}:</span>
                    <div className="ml-2">
                      {Object.keys(value).length > 0 ? (
                        Object.entries(value).map(([subKey, subValue]) => (
                          <div key={subKey}>
                            <span className="font-medium">{formatTitle(subKey)}:</span>
                            {Array.isArray(subValue) && subValue.length > 0 ? (
                              subValue.map((item, idx) => (
                                <a
                                  key={idx}
                                  href={item}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="block text-brand-500 hover:underline"
                                >
                                  {item}
                                </a>
                              ))
                            ) : (
                              <span className="ml-2">None</span>
                            )}
                          </div>
                        ))
                      ) : (
                        <span className="ml-2">None</span>
                      )}
                    </div>
                  </div>
                );
              }

              // If the value is a number, format it
              const displayValue = typeof value === 'number' ? formatNumber(value) : value;

              return (
                <div key={key}>
                  <span className="font-medium">{formatTitle(key)}:</span>
                  <span className="ml-2">{displayValue}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Genesis Date Section */}
      {cryptoData.genesis_date && (
        <div className="mb-6">
          <h3 className="text-2xl font-semibold text-navy-700">Genesis Date</h3>
          <p className="mt-2 text-gray-600">
            {new Date(cryptoData.genesis_date).toLocaleDateString()}
          </p>
        </div>
      )}

      {/* Hashing Algorithm Section */}
      {cryptoData.hashing_algorithm && (
        <div className="mb-6">
          <h3 className="text-2xl font-semibold text-navy-700">Hashing Algorithm</h3>
          <p className="mt-2 text-gray-600">
            {cryptoData.hashing_algorithm}
          </p>
        </div>
      )}

      {/* Important Links Section */}
      <div className="mb-6">
        <h3 className="text-2xl font-semibold text-navy-700">Important Links</h3>
        {renderLinks()}
      </div>

      {/* Additional Notices Section */}
      {cryptoData.additional_notices && Array.isArray(cryptoData.additional_notices) && cryptoData.additional_notices.length > 0 && (
        <div className="mb-6">
          <h3 className="text-2xl font-semibold text-navy-700">Additional Notices</h3>
          <div className="mt-2 space-y-2 text-gray-600">
            {cryptoData.additional_notices.map((notice, index) => (
              <span key={index} className="block">
                {notice}
              </span>
            ))}
          </div>
        </div>
      )}

      {/* Platforms Section */}
      {cryptoData.platforms && typeof cryptoData.platforms === 'object' && Object.values(cryptoData.platforms).some(platform => platform) && (
        <div className="mb-6">
          <h3 className="text-2xl font-semibold text-navy-700">Platforms</h3>
          <div className="mt-2 flex flex-wrap gap-2">
            {Object.values(cryptoData.platforms).map((platform, index) => (
              platform ? (
                <span
                  key={index}
                  className="px-2 py-1 bg-gray-200 rounded-full text-sm"
                >
                  {platform}
                </span>
              ) : null
            ))}
          </div>
        </div>
      )}

      {/* Sentiment Section */}
{(cryptoData.sentiment_votes_up_percentage !== undefined && cryptoData.sentiment_votes_down_percentage !== undefined) && (
  <div className="mb-6">
    <h3 className="text-2xl font-semibold text-navy-700">Sentiment</h3>
    <div className="mt-2 grid grid-cols-2 gap-4 text-gray-600">
      
      {/* Votes Up Percentage */}
      <div>
        <span className="font-medium">Votes Up Percentage:</span>
        <span
          className={`ml-2 text-xs font-normal ${
            cryptoData.sentiment_votes_up_percentage < 0 ? 'text-red-500' : 'text-green-500'
          }`}
        >
          {formatPercentage(cryptoData.sentiment_votes_up_percentage)}
        </span>
      </div>
      
      {/* Votes Down Percentage */}
      <div>
        <span className="font-medium">Votes Down Percentage:</span>
        <span
          className={`ml-2 text-xs font-normal ${
            cryptoData.sentiment_votes_down_percentage > 0 ? 'text-red-500' : 'text-green-500'
          }`}
        >
          {formatPercentage(cryptoData.sentiment_votes_down_percentage)}
        </span>
      </div>
      
    </div>
  </div>
)}

    </Card>
  );
};

export default Project;
