import React, { useEffect, useState, useRef } from "react";
import Card from "components/card";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { FaArrowUp, FaArrowDown } from "react-icons/fa"; // Optional: Import icons for additional UI enhancements

const Widget = ({ icon }) => {
  const [cryptos, setCryptos] = useState([]);
  const [loading, setLoading] = useState(true);
  const scrollRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const beginRange = 0;
  const endRange = 1000;

  // Helper function to format price with commas and proper decimal places
  const formatPrice = (price) => {
    if (price === null || price === undefined) return "N/A";
    if (price === 0) return "0.00";
  
    const absPrice = Math.abs(price);
    let formattedPrice;
  
    if (absPrice < 0.0001) {
      // For extremely small prices, use toFixed to prevent scientific notation
      formattedPrice = price.toFixed(12);
    } else if (absPrice < 0.01) {
      // For very small prices, use toPrecision and fallback to toFixed if necessary
      formattedPrice = price.toPrecision(10);
      if (formattedPrice.includes('e')) {
        formattedPrice = price.toFixed(10);
      }
    } else if (absPrice < 1) {
      // For small prices, ensure at least two decimal places
      formattedPrice = price.toPrecision(5);
      if (formattedPrice.includes('e')) {
        formattedPrice = price.toFixed(5);
      }
    } else {
      // For prices >= $1, use two decimal places
      formattedPrice = price.toFixed(2);
    }
  
    // **New Code Starts Here**
    // Split the formatted price into integer and decimal parts
    const parts = formattedPrice.toString().split(".");
  
    // Use a regular expression to add commas to the integer part
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    // Rejoin the integer and decimal parts
    return parts.join(".");
    // **New Code Ends Here**
  };
  
  

  // Fetch data from the trending API
  useEffect(() => {
    const fetchCryptos = async () => {
      setLoading(true); // Show loading indicator when fetching data
      try {
        const apiEndpoint = `/api/socialinfo?beginRange=${beginRange}&endRange=${endRange}`;

        const response = await fetch(apiEndpoint);

        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }

        const data = await response.json();

        if (data.cryptos) {
          let cryptosData = data.cryptos;

          // Take the top 50 trending cryptocurrencies
          cryptosData = cryptosData.slice(0, 50);

          setCryptos(cryptosData);
          setLoading(false);
        } else {
          console.error("No cryptos found in the response:", data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching crypto data:", error);
        setLoading(false);
      }
    };

    fetchCryptos();
  }, []); // Empty dependency array to run once on mount

  // Handlers for hover events
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Utility function to format numbers with commas
  const formatNumberWithCommas = (number) => {
    if (number === "N/A" || isNaN(Number(number))) return "N/A";
    return Number(number).toLocaleString();
  };

  return (
    <Card extra="!flex-row flex-grow h-[90px] items-center rounded-[20px] overflow-hidden relative">
      {/* Icon and Title Positioned at Upper Left */}
      <div className="absolute top-1 left-1 flex items-center">
        <span className="flex text-sm items-center text-brand-400 dark:text-white">
          {icon}{" "}
          <p className="font-dm text-sm font-normal text-gray-500 ml-1">
            Top 50 Social Media Trending
          </p>
        </span>
      </div>

      {/* Marquee or Scrollable Content */}
      <div
        className="flex-grow overflow-hidden mt-5"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          scrollbarWidth: 'none', /* Firefox */
          '-ms-overflow-style': 'none', /* IE and Edge */
        }}
      >
        {/* WebKit browsers */}
        <style>{`
          .scrollbar-hidden::-webkit-scrollbar {
            display: none; /* Hides the scrollbar */
          }
        `}</style>
        {loading ? (
          <p>Loading crypto data...</p>
        ) : cryptos.length > 0 ? (
          isHovered ? (
            // Render a scrollable div when hovered
            <div
              className="flex space-x-6 overflow-x-auto scrollbar-hidden"
              ref={scrollRef}
              style={{ scrollBehavior: "smooth" }}
            >
              {cryptos.map((crypto, index) => {
                const price = parseFloat(crypto.current_price);
                const priceChange = parseFloat(crypto.price_change_percentage_24h);
                const isPositive = priceChange >= 0;
                const formattedPrice = formatPrice(price);
                const formattedPriceChange = Math.abs(priceChange).toFixed(2);

                return (
                  <div
                    key={crypto.id} // Use unique id
                    className="flex-shrink-0 flex items-center bg-white dark:bg-gray-800 shadow rounded-lg p-2 transform transition duration-200 hover:scale-105 hover:shadow-lg cursor-pointer"
                    onClick={() => navigate(`/admin/trending/${crypto.id}`)} // Redirect on click
                  >
                    {/* Ranking */}
                    <span className="mr-2 text-sm font-bold text-gray-700 dark:text-gray-300">
                      {index + 1}.
                    </span>

                    {/* Logo */}
                    <img
                      src={crypto.logo_url}
                      alt={`${crypto.identity} logo`}
                      className="h-8 w-8 mr-2"
                    />

                    {/* Crypto details */}
                    <div className="text-gray-700 dark:text-gray-300">
                      <span className="block text-sm font-semibold">
                        {crypto.identity} ({crypto.symbol.toUpperCase()})
                      </span>
                      <span className="block text-xs font-medium">
                        Price: ${formattedPrice}{" "}
                        <span
                          className={`${
                            isPositive ? "text-green-500" : "text-red-500"
                          } flex items-center`}
                        >
                          {isPositive ? <FaArrowUp /> : <FaArrowDown />}{" "}
                          {formattedPriceChange}%
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            // Render the marquee when not hovered
            <Marquee gradient={false} speed={30} pauseOnHover={true}>
              {cryptos.map((crypto, index) => {
                const price = parseFloat(crypto.current_price);
                const priceChange = parseFloat(crypto.price_change_percentage_24h);
                const isPositive = priceChange >= 0;
                const formattedPrice = formatPrice(price);
                const formattedPriceChange = Math.abs(priceChange).toFixed(2);

                return (
                  <div
                    key={crypto.id} // Use unique id
                    className="flex items-center mx-6 transform transition duration-200 hover:scale-105 hover:shadow-lg cursor-pointer"
                    onClick={() => navigate(`/admin/trending/${crypto.id}`)} // Redirect on click
                  >
                    {/* Ranking */}
                    <span className="mr-2 text-sm font-bold text-gray-700 dark:text-gray-300">
                      {index + 1}.
                    </span>

                    {/* Logo */}
                    <img
                      src={crypto.logo_url}
                      alt={`${crypto.identity} logo`}
                      className="h-8 w-8 mr-2"
                    />

                    {/* Crypto details */}
                    <div className="text-gray-700 dark:text-gray-300">
                      <span className="block text-sm font-semibold">
                        {crypto.identity} ({crypto.symbol.toUpperCase()})
                      </span>
                      <span className="block text-xs font-medium">
                        Price: ${formattedPrice}{" "}
                        <span
                          className={`${
                            isPositive ? "text-green-500" : "text-red-500"
                          } flex items-center`}
                        >
                          {isPositive ? <FaArrowUp /> : <FaArrowDown />}{" "}
                          {formattedPriceChange}%
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
            </Marquee>
          )
        ) : (
          <p>No cryptos found to display.</p>
        )}
      </div>
    </Card>
  );
};

export default Widget;
