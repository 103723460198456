import React, { useState } from "react";
import Card from "components/card";
import PropTypes from "prop-types";
import { FaArrowUp, FaArrowDown } from "react-icons/fa"; // Importing icons from react-icons

// Utility functions for formatting numbers and percentages
const formatNumber = (number) => {
  if (number === "N/A" || isNaN(Number(number))) return "N/A";
  return Number(number).toLocaleString(undefined, { maximumFractionDigits: 2 });
};

const formatPercentage = (percentage) => {
  if (percentage === "N/A" || isNaN(Number(percentage))) return "N/A";
  return `${Number(percentage).toFixed(2)}%`;
};

// Function to calculate difference and determine styling
const calculateDifference = (current, previous, isRank = false) => {
  if (
    current === "N/A" ||
    previous === "N/A" ||
    current === undefined ||
    previous === undefined ||
    isNaN(Number(current)) ||
    isNaN(Number(previous))
  ) {
    return { diff: null, color: "", icon: null };
  }

  const curr = Number(current);
  const prev = Number(previous);
  let diff = 0;

  if (isRank) {
    // For ranks, lower is better. Difference is previous - current.
    diff = prev - curr;
  } else {
    // For scores, higher is better. Difference is current - previous.
    diff = curr - prev;
  }

  if (diff > 0) {
    return {
      diff: `+${diff}`,
      color: "text-green-500",
      icon: <FaArrowUp className="inline ml-1" />,
    };
  } else if (diff < 0) {
    return {
      diff: `${diff}`,
      color: "text-red-500",
      icon: <FaArrowDown className="inline ml-1" />,
    };
  } else {
    return { diff: null, color: "", icon: null };
  }
};

const Upload = ({ coinData }) => {
  // State to hold the USD input value
  const [usdAmount, setUsdAmount] = useState("");

  // Function to handle changes in the USD input field
  const handleUsdChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setUsdAmount(value);
    }
  };

  // Function to calculate the Crypto amount based on USD input
  const calculateCrypto = () => {
    const currentPrice = parseFloat(coinData?.current_price);
    if (usdAmount === "" || isNaN(currentPrice) || currentPrice === 0) {
      return "0";
    }
    const cryptoAmount = parseFloat(usdAmount) / currentPrice;
    return cryptoAmount.toFixed(6);
  };

  return (
    <Card className="grid min-h-[40vh] w-full grid-cols-3 gap-2 rounded-[15px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none">
      
      {/* USD Converter Section */}
      <div className="col-span-1 max-h-[40vh] rounded-xl bg-lightPrimary dark:!bg-navy-700 p-3 flex flex-col items-center justify-center">
        <h4 className="text-md font-semibold text-brand-500 dark:text-white mb-1">
          USD Converter
        </h4>
        <div className="w-full">
          {/* USD Input */}
          <div className="flex flex-col mb-2">
            <label className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-1">
              USD
            </label>
            <input
              type="number"
              placeholder="Enter USD amount"
              value={usdAmount}
              onChange={handleUsdChange}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 dark:bg-navy-700 dark:border-navy-600 dark:text-white p-1"
            />
          </div>
          {/* Crypto Output */}
          <div className="flex flex-col">
            <label className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-1">
              {coinData?.name || "Crypto"}
            </label>
            <input
              type="text"
              placeholder="Crypto amount"
              value={calculateCrypto()}
              readOnly
              className="block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 dark:bg-navy-600 dark:border-navy-600 dark:text-white p-1"
            />
          </div>
        </div>
      </div>

      {/* Social Information Section */}
      <div className="col-span-2 flex flex-col p-3 rounded-xl bg-white dark:!bg-navy-800">
        <h5 className="text-lg font-semibold text-navy-700 dark:text-white mb-2">
          Social Information
        </h5>
        <div className="grid grid-cols-2 gap-2">
          {/* Social Information Cards */}
          {[
            { label: "Social Interactions (24h)", value: formatNumber(coinData?.interactions_24h) },
            { label: "Social Volume (24h)", value: formatNumber(coinData?.social_volume_24h) },
            { label: "Social Dominance", value: formatPercentage(coinData?.social_dominance) },
            { label: "Sentiment", value: formatPercentage(coinData?.sentiment) },
            // Galaxy Score with difference
            {
              label: "Galaxy Score",
              value: (() => {
                const { diff, color, icon } = calculateDifference(
                  coinData?.galaxy_score,
                  coinData?.galaxy_score_previous,
                  false
                );
                return (
                  <>
                    {coinData?.galaxy_score || "N/A"}
                    {diff && (
                      <span className={`${color} ml-1`}>
                        {diff}
                        {icon}
                      </span>
                    )}
                  </>
                );
              })(),
            },
            // Galaxy Score Previous
            {
              label: "Galaxy Score Previous",
              value: coinData?.galaxy_score_previous || "N/A",
            },
            // Alternative Rank with difference
            {
              label: "Alternative Rank",
              value: (() => {
                const { diff, color, icon } = calculateDifference(
                  coinData?.alt_rank,
                  coinData?.alt_rank_previous,
                  true
                );
                return (
                  <>
                    {coinData?.alt_rank || "N/A"}
                    {diff && (
                      <span className={`${color} ml-1`}>
                        {diff}
                        {icon}
                      </span>
                    )}
                  </>
                );
              })(),
            },
            // Alternative Rank Previous
            {
              label: "Alternative Rank Previous",
              value: coinData?.alt_rank_previous || "N/A",
            },
            { label: "Categories", value: coinData?.categories || "N/A" },
            { label: "Topic", value: coinData?.topic || "N/A" },
            // Removed Blockchains and Last Updated Fields
          ].map((item, index) => (
            <div key={index} className="flex flex-col p-2 rounded-lg shadow bg-white dark:bg-navy-700 overflow-hidden">
              <span className="text-xs font-normal text-gray-600 dark:text-gray-300">
                {item.label}
              </span>
              <span className={`text-sm font-bold text-navy-700 dark:text-white`}>
                {item.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

// Updated PropTypes for flat data structure
Upload.propTypes = {
  coinData: PropTypes.shape({
    id: PropTypes.number, // Assuming 'id' is a number
    symbol: PropTypes.string,
    name: PropTypes.string,
    current_price: PropTypes.string,
    market_cap_rank: PropTypes.number,
    price_btc: PropTypes.string,
    market_cap: PropTypes.string,
    market_dominance: PropTypes.string,
    market_dominance_prev: PropTypes.string,
    total_volume: PropTypes.string,
    volume_24h: PropTypes.string,
    circulating_supply: PropTypes.string,
    total_supply: PropTypes.string,
    max_supply: PropTypes.string,
    price_change_percentage_1h: PropTypes.string,
    price_change_percentage_7d: PropTypes.string,
    price_change_percentage_24h: PropTypes.string,
    price_change_percentage_30d: PropTypes.string,
    sentiment: PropTypes.string,
    social_dominance: PropTypes.string,
    social_volume_24h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    interactions_24h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    galaxy_score: PropTypes.string,
    galaxy_score_previous: PropTypes.string,
    alt_rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alt_rank_previous: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    categories: PropTypes.string,
    topic: PropTypes.string,
    // Removed blockchains and last_updated_price fields
    logo_url: PropTypes.string,
    color: PropTypes.string,
    timestamp: PropTypes.string, // If you still need it
  }).isRequired,
};

export default Upload;
